
/* ###### Height  ###### */
.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

/* ###### 9.3 Height   ###### */

.ht-5 {
  height: 5px;
}

.ht-10 {
  height: 10px;
}

.ht-15 {
  height: 15px;
}

.ht-20 {
  height: 20px;
}

.ht-25 {
  height: 25px;
}

.ht-30 {
  height: 30px;
}

.ht-35 {
  height: 35px;
}

.ht-40 {
  height: 40px;
}

.ht-45 {
  height: 45px;
}

.ht-50 {
  height: 50px;
}

.ht-55 {
  height: 55px;
}

.ht-60 {
  height: 60px;
}

.ht-65 {
  height: 65px;
}

.ht-70 {
  height: 70px;
}

.ht-75 {
  height: 75px;
}

.ht-80 {
  height: 80px;
}

.ht-85 {
  height: 85px;
}

.ht-90 {
  height: 90px;
}

.ht-95 {
  height: 95px;
}

.ht-100 {
  height: 100px !important;
}

.ht-100p {
  height: 100%;
}

.ht-136 {
  height: 136px;
}

.ht-150 {
  height: 150px;
}

.ht-175 {
  height: 175px;
}

.ht-180 {
  height: 180px;
}

.ht-200 {
  height: 200px;
}

.ht-210 {
  height: 210px;
}

.ht-220 {
  height: 220px;
}

.ht-226 {
  height: 226px;
}

.ht-230 {
  height: 230px !important;
}

.ht-238 {
  height: 238px !important;
}

.ht-250 {
  height: 250px;
}

.ht-270 {
  height: 270px;
}

.ht-300 {
  height: 300px;
}

.ht-350 {
  height: 350px;
}

.ht-400 {
  height: 400px;
}

.ht-450 {
  height: 450px;
}

.ht-500 {
  height: 500px;
}

.ht-550 {
  height: 550px;
}

.ht-600 {
  height: 600px;
}

.ht-650 {
  height: 650px;
}

.ht-700 {
  height: 700px;
}

.ht-750 {
  height: 750px;
}

.ht-800 {
  height: 800px;
}

.ht-100v {
  height: 100vh;
}

.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-4 {
  height: 4px;
}

.ht-6 {
  height: 6px;
}

.ht-7 {
  height: 7px;
}

.ht-8 {
  height: 8px;
}

.ht-9 {
  height: 9px;
}

.ht-auto {
  height: auto;
}

.ht-16 {
  height: 16px;
}

.ht-32 {
  height: 32px;
}

.ht-36 {
  height: 36px;
}

.ht-48 {
  height: 48px;
}

.ht-64 {
  height: 64px;
}

.ht-72 {
  height: 72px;
}

.ht-115 {
  height: 115px;
}

.ht-120 {
  height: 120px;
}

.ht-160 {
  height: 160px;
}

@media (min-width: 480px) {
  .ht-xs-5 {
    height: 5px;
  }

  .ht-xs-10 {
    height: 10px;
  }

  .ht-xs-15 {
    height: 15px;
  }

  .ht-xs-20 {
    height: 20px;
  }

  .ht-xs-25 {
    height: 25px;
  }

  .ht-xs-30 {
    height: 30px;
  }

  .ht-xs-35 {
    height: 35px;
  }

  .ht-xs-40 {
    height: 40px;
  }

  .ht-xs-45 {
    height: 45px;
  }

  .ht-xs-50 {
    height: 50px;
  }

  .ht-xs-55 {
    height: 55px;
  }

  .ht-xs-60 {
    height: 60px;
  }

  .ht-xs-65 {
    height: 65px;
  }

  .ht-xs-70 {
    height: 70px;
  }

  .ht-xs-75 {
    height: 75px;
  }

  .ht-xs-80 {
    height: 80px;
  }

  .ht-xs-85 {
    height: 85px;
  }

  .ht-xs-90 {
    height: 90px;
  }

  .ht-xs-95 {
    height: 95px;
  }

  .ht-xs-100 {
    height: 100px;
  }

  .ht-xs-150 {
    height: 150px;
  }

  .ht-xs-200 {
    height: 200px;
  }

  .ht-xs-250 {
    height: 250px;
  }

  .ht-xs-300 {
    height: 300px;
  }

  .ht-xs-350 {
    height: 350px;
  }

  .ht-xs-400 {
    height: 400px;
  }

  .ht-xs-450 {
    height: 450px;
  }

  .ht-xs-500 {
    height: 500px;
  }

  .ht-xs-550 {
    height: 550px;
  }

  .ht-xs-600 {
    height: 600px;
  }

  .ht-xs-650 {
    height: 650px;
  }

  .ht-xs-700 {
    height: 700px;
  }

  .ht-xs-750 {
    height: 750px;
  }

  .ht-xs-800 {
    height: 800px;
  }

  .ht-xs-850 {
    height: 850px;
  }

  .ht-xs-100v {
    height: 100vh;
  }
}

@media (min-width: 576px) {
  .ht-sm-5 {
    height: 5px;
  }

  .ht-sm-10 {
    height: 10px;
  }

  .ht-sm-15 {
    height: 15px;
  }

  .ht-sm-20 {
    height: 20px;
  }

  .ht-sm-25 {
    height: 25px;
  }

  .ht-sm-30 {
    height: 30px;
  }

  .ht-sm-35 {
    height: 35px;
  }

  .ht-sm-40 {
    height: 40px;
  }

  .ht-sm-45 {
    height: 45px;
  }

  .ht-sm-50 {
    height: 50px;
  }

  .ht-sm-55 {
    height: 55px;
  }

  .ht-sm-60 {
    height: 60px;
  }

  .ht-sm-65 {
    height: 65px;
  }

  .ht-sm-70 {
    height: 70px;
  }

  .ht-sm-75 {
    height: 75px;
  }

  .ht-sm-80 {
    height: 80px;
  }

  .ht-sm-85 {
    height: 85px;
  }

  .ht-sm-90 {
    height: 90px;
  }

  .ht-sm-95 {
    height: 95px;
  }

  .ht-sm-100 {
    height: 100px;
  }

  .ht-sm-150 {
    height: 150px;
  }

  .ht-sm-200 {
    height: 200px;
  }

  .ht-sm-250 {
    height: 250px;
  }

  .ht-sm-300 {
    height: 300px;
  }

  .ht-sm-350 {
    height: 350px;
  }

  .ht-sm-400 {
    height: 400px;
  }

  .ht-sm-450 {
    height: 450px;
  }

  .ht-sm-500 {
    height: 500px;
  }

  .ht-sm-550 {
    height: 550px;
  }

  .ht-sm-600 {
    height: 600px;
  }

  .ht-sm-650 {
    height: 650px;
  }

  .ht-sm-700 {
    height: 700px;
  }

  .ht-sm-750 {
    height: 750px;
  }

  .ht-sm-800 {
    height: 800px;
  }

  .ht-sm-850 {
    height: 850px;
  }

  .ht-sm-100v {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .ht-md-5 {
    height: 5px;
  }

  .ht-md-10 {
    height: 10px;
  }

  .ht-md-15 {
    height: 15px;
  }

  .ht-md-20 {
    height: 20px;
  }

  .ht-md-25 {
    height: 25px;
  }

  .ht-md-30 {
    height: 30px;
  }

  .ht-md-35 {
    height: 35px;
  }

  .ht-md-40 {
    height: 40px;
  }

  .ht-md-45 {
    height: 45px;
  }

  .ht-md-50 {
    height: 50px;
  }

  .ht-md-55 {
    height: 55px;
  }

  .ht-md-60 {
    height: 60px;
  }

  .ht-md-65 {
    height: 65px;
  }

  .ht-md-70 {
    height: 70px;
  }

  .ht-md-75 {
    height: 75px;
  }

  .ht-md-80 {
    height: 80px;
  }

  .ht-md-85 {
    height: 85px;
  }

  .ht-md-90 {
    height: 90px;
  }

  .ht-md-95 {
    height: 95px;
  }

  .ht-md-100 {
    height: 100px;
  }

  .ht-md-150 {
    height: 150px;
  }

  .ht-md-200 {
    height: 200px;
  }

  .ht-md-250 {
    height: 250px;
  }

  .ht-md-300 {
    height: 300px;
  }

  .ht-md-350 {
    height: 350px;
  }

  .ht-md-400 {
    height: 400px;
  }

  .ht-md-450 {
    height: 450px;
  }

  .ht-md-500 {
    height: 500px;
  }

  .ht-md-550 {
    height: 550px;
  }

  .ht-md-600 {
    height: 600px;
  }

  .ht-md-650 {
    height: 650px;
  }

  .ht-md-700 {
    height: 700px;
  }

  .ht-md-750 {
    height: 750px;
  }

  .ht-md-800 {
    height: 800px;
  }

  .ht-md-850 {
    height: 850px;
  }

  .ht-md-100v {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .ht-lg-5 {
    height: 5px;
  }

  .ht-lg-10 {
    height: 10px;
  }

  .ht-lg-15 {
    height: 15px;
  }

  .ht-lg-20 {
    height: 20px;
  }

  .ht-lg-25 {
    height: 25px;
  }

  .ht-lg-30 {
    height: 30px;
  }

  .ht-lg-35 {
    height: 35px;
  }

  .ht-lg-40 {
    height: 40px;
  }

  .ht-lg-45 {
    height: 45px;
  }

  .ht-lg-50 {
    height: 50px;
  }

  .ht-lg-55 {
    height: 55px;
  }

  .ht-lg-60 {
    height: 60px;
  }

  .ht-lg-65 {
    height: 65px;
  }

  .ht-lg-70 {
    height: 70px;
  }

  .ht-lg-75 {
    height: 75px;
  }

  .ht-lg-80 {
    height: 80px;
  }

  .ht-lg-85 {
    height: 85px;
  }

  .ht-lg-90 {
    height: 90px;
  }

  .ht-lg-95 {
    height: 95px;
  }

  .ht-lg-100 {
    height: 100px;
  }

  .ht-lg-150 {
    height: 150px;
  }

  .ht-lg-200 {
    height: 200px;
  }

  .ht-lg-250 {
    height: 250px;
  }

  .ht-lg-300 {
    height: 300px;
  }

  .ht-lg-350 {
    height: 350px;
  }

  .ht-lg-400 {
    height: 400px;
  }

  .ht-lg-450 {
    height: 450px;
  }

  .ht-lg-500 {
    height: 500px;
  }

  .ht-lg-550 {
    height: 550px;
  }

  .ht-lg-600 {
    height: 600px;
  }

  .ht-lg-650 {
    height: 650px;
  }

  .ht-lg-700 {
    height: 700px;
  }

  .ht-lg-750 {
    height: 750px;
  }

  .ht-lg-800 {
    height: 800px;
  }

  .ht-lg-850 {
    height: 850px;
  }

  .ht-lg-100v {
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .ht-xl-5 {
    height: 5px;
  }

  .ht-xl-10 {
    height: 10px;
  }

  .ht-xl-15 {
    height: 15px;
  }

  .ht-xl-20 {
    height: 20px;
  }

  .ht-xl-25 {
    height: 25px;
  }

  .ht-xl-30 {
    height: 30px;
  }

  .ht-xl-35 {
    height: 35px;
  }

  .ht-xl-40 {
    height: 40px;
  }

  .ht-xl-45 {
    height: 45px;
  }

  .ht-xl-50 {
    height: 50px;
  }

  .ht-xl-55 {
    height: 55px;
  }

  .ht-xl-60 {
    height: 60px;
  }

  .ht-xl-65 {
    height: 65px;
  }

  .ht-xl-70 {
    height: 70px;
  }

  .ht-xl-75 {
    height: 75px;
  }

  .ht-xl-80 {
    height: 80px;
  }

  .ht-xl-85 {
    height: 85px;
  }

  .ht-xl-90 {
    height: 90px;
  }

  .ht-xl-95 {
    height: 95px;
  }

  .ht-xl-100 {
    height: 100px;
  }

  .ht-xl-150 {
    height: 150px;
  }

  .ht-xl-200 {
    height: 200px;
  }

  .ht-xl-250 {
    height: 250px;
  }

  .ht-xl-300 {
    height: 300px;
  }

  .ht-xl-350 {
    height: 350px;
  }

  .ht-xl-400 {
    height: 400px;
  }

  .ht-xl-450 {
    height: 450px;
  }

  .ht-xl-500 {
    height: 500px;
  }

  .ht-xl-550 {
    height: 550px;
  }

  .ht-xl-600 {
    height: 600px;
  }

  .ht-xl-650 {
    height: 650px;
  }

  .ht-xl-700 {
    height: 700px;
  }

  .ht-xl-750 {
    height: 750px;
  }

  .ht-xl-800 {
    height: 800px;
  }

  .ht-xl-850 {
    height: 850px;
  }

  .ht-xl-100v {
    height: 100vh;
  }
}

/* ###### Height End ###### */