/* ###### Landing   ###### */

.landing-top-header {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    position: relative;
    border-radius: 0px;
}

.landing-top-header .top {
    border-block-end: 1px solid transparent;

    .nav {
        li {
            margin-inline-start: 0;
            margin-inline-end: 28px;

            a {
                color: $default-color;
                font-size: 15px;
                text-transform: capitalize;

                &.active {
                    color: $primary;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .landing-page {
        .nav.navbar-nav.nav-pills {
            display: block;
        }

        .navbar-toggler {
            border: 0 !important;
        }

        .navbar.navbar-expand-lg.navbar-light {
            background-color: $white;
        }

        .side-menu__item {
            padding-inline-start: 25px;
        }

        .landing-app-sidebar.horizontalmenu {
            background-color: $white  !important;
        }
        .side-header.hor-header {
            height: 60px;
            background-color: $white;
            border-block-end: 1px solid $border;
            z-index: 99;
            width: 100%;
            box-shadow: -7.829px 11.607px 20px 0px #6d6d8714;
        }
        .main-header-center{
            display: block;
            margin: 0;
            position: relative;
            margin: 0 auto;
            text-align: center;
        }
        .hor-header .responsive-logo img{
            height: 2rem;
        }
        .hor-header .responsive-logo .mobile-logo-dark{
            display: none;
        }
        .side-menu__item.active, .side-menu__item:hover, .side-menu__item:focus{
            background: transparent;
        }
        .responsive-navbar.navbar .navbar-collapse{
            background: $white;
            box-shadow: -7.829px 11.607px 20px 0px #6d6d8714;
        }
        .responsive-navbar.navbar .navbar-collapse{
            top: 60px;
        }
        .side-menu__item.active .side-menu__label, .side-menu__item:hover .side-menu__label, .side-menu__item:focus .side-menu__label{
            color: $default-color !important;
        }
    }
}

.landing-top-header .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent;
    font-weight: bold;
    color: $primary;
}

.logo-demo {
    padding-block-start: 0px;
    margin: 15px 0;
}

.spacing-top {
    padding-block-start: 50px;
}

.demo-screen-headline h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    text-align: center;
}

.demo-screen-headline h1 {
    line-height: 1.4;
    margin-block-end: 20px;
    text-shadow: none;
}

.demo-screen-headline {
    padding-block-start: 100px;
    padding-block-end: 70px;
}

.landing-top-header .demo-screen-headline h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.demo-footer {
    background-color: $dark-theme;
    padding-block-start: 3rem;
    color: $dark-color;

    .card {
        background-color: $dark-theme;
    }

    .main-footer {
        background-color: $dark-theme;
    }

    hr {
        border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }

    .footer-social-list a {
        color: $white;
    }

    .top-footer img {
        padding: 0;
        width: auto !important;
        height: auto !important;
    }

    .card.container {
        box-shadow: none;
    }

}

.landing-page {
    .bottom-footer-shape {
        bottom: -2px;
        position: absolute;
        pointer-events: none;
        inset-inline-end: 0;
        inset-inline-start: 0;
        width: 100%;
        color: $dark-theme  !important;
    }

    .btn-icon i {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
    }

    .counter-icon i {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    .shape svg {
        -webkit-transform: scale(2);
        transform: scale(2);
        width: 100%;
        height: auto;
        -webkit-transform-origin: top center;
        transform-origin: top center;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    .svg-icons {
        width: 25px;
        height: 25px;
    }

    .card {
        border: 0px;
        position: relative;
        margin-block-end: 1.5rem;
        width: 100%;
    }

    background-color: $white;

    .pricing-card.advanced {
        box-shadow: 0px 3px 14px rgb(33 33 33 / 15%);
    }

    .demo-footer {
        .btn-list {
            .btn-icon {
                border: 1px solid $white-1;
                color: $dark-color;
                background-color: $white-2;
                padding: 5px;
            }
        }
    }

    .feature-1 {
        border: 1px solid #e9edf4;
        padding: 22px;
        border-radius: 10px;
        position: relative;
        text-align: center;

        a {
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .top-footer h6 {
        font-size: 17px;
        color: $white;
    }

    .owl-controls .owl-page {
        display: inline-block;
        zoom: 1;
    }

    .owl-controls .owl-page {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: url(../img/users/1.jpg) no-repeat;
        background-size: cover;
        transition: all 0.3s ease-in-out 0s;
        border: 4px solid $border;
    }

    .owl-controls .owl-page:nth-child(2) {
        background: url(../img/users/18.jpg) no-repeat;
        background-size: cover;
    }

    .owl-controls .owl-page:nth-child(3) {
        background: url(../img/users/8.jpg) no-repeat;
        background-size: cover;
    }

    .testimonia .post {
        color: $white;
    }

    .owl-controls .owl-page.active {
        border-color: $danger;
        opacity: 1;
    }

    .testimonia .title {
        color: $white;
    }

    .rating-stars .jq-star {
        width: 18px !important;
        height: 18px !important;
    }

    .services-statistics .counter-icon {
        border-radius: 0 40% 0 40%;
    }

    .footer-social-list a {
        font-size: inherit;
        background: #74829c;
        border-radius: 50px;
        padding: 8px 13px;
    }

    .card {
        box-shadow: none;
    }

    .featured-icon {
        height: 55px;
        width: 55px;
        border-radius: 26% 74% 73% 27% / 30% 49% 51% 70%;
    }

    .featured-icon:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: 26% 74% 73% 27% / 30% 49% 51% 70%;
        inset-inline-start: 0;
        background-color: #000;
        z-index: inherit;
        transform: rotate(23deg);
    }

    .card.features.main-features {
        border: 1px solid $border  !important;
        box-shadow: none !important;
        transition: all ease 0.3s !important;
        text-align: center;
    }

    
    .reveal.revealleft.active {
        transform: translateY(0px);
        opacity: 1;
    }
    .reveal.revealleft {
        transform: translateY(-30px);
        opacity: 0;
        transition: all 0.5s ease;
    }


    .owl-item {
        border-radius: 5px;

        img {
            border: 1px solid #e9edf4;
            border-radius: 5px;
        }
    }

    .bg-pages .card {
        border-radius: 50px 10px;
        border: 0 !important;
    }

    .bg-pages .feature-icon {
        width: 55px;
        height: 55px;
        text-align: center;
        border-radius: 50%;
        position: relative;
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 0;
        text-align: center;
        line-height: 0;
        padding: 15px;
        bottom: 0;
    }

    .bg-pages .feature-icon i {
        font-size: 25px;
    }

    .bg-pages .card {
        border-radius: 50px 10px;
        border: 1px solid $border  !important;
        background-color: #f4f4f7;
    }

    .faqs .card .card-body {
        padding: 3.5rem 3.5rem 1.5rem 3.5rem;
        transition: 0.3s ease all;
    }

    .faqs .card {
        padding: 1rem;
        transition: 0.3s ease all;
    }

    .faqs .card-header {
        background: none;
        padding: 1.0rem 1.0rem;
        display: flex;
        align-items: center;
        margin-block-end: 0;
        background-color: transparent;
        letter-spacing: 0.2px;
        width: 100% !important;
        height: 100% !important;
        border-block-end: 0;
    }

    .faqs .card-collapsed .card-header {
        border-radius: 7px;
    }

    .bg-pages .card:hover {
        box-shadow: 0px 6px 18px 4px rgb(6 10 48 / 8%);
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }

    .bg-landing {
        background-color: #f8f9fb;
    }

    .form-landing {
        .form-control {
            border-start-start-radius: 40px;
            border-end-start-radius: 40px;
        }

        .btn {
            border-start-end-radius: 40px;
            border-end-end-radius: 40px;
        }
    }

    .bg-image-landing {
        background-image: url(../landing/images/bg4.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        position: relative;

        &::before {
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            content: "";
            inset-inline-start: -19%;
            inset-inline-end: 0;
            width: 35%;
            height: 80%;
            top: 0px;
            background-image: url(../landing/images/bg.png);
        }
    }

    .landing-statistics {
        .card-body:hover::before {
            width: 0px;
            background: $white;
        }

        .card-body:hover::after {
            height: 0px;
            background: $white;
        }

        .counter-icon {
            border-radius: 10px;
            border: transparent;
            background: $black-2;
            width: 4rem;
            height: 4rem;
            padding: 17px 18px;
            margin: 0 auto;
            margin-block-end: 1rem;

            i {
                color: #fff;
                font-size: 1.7rem;
            }
        }
    }

    .text-white-80 {
        color: rgba(255, 255, 255, 0.8);
    }

    .landing-title {
        background: linear-gradient(to right, transparent, $primary, transparent);
        height: 1px;
        margin: 0px auto;
        width: 70px;
        margin-block-end: 10px;
    }
    .feature-section {
        .landing-title {
            background: linear-gradient(to right, transparent, $white, transparent);
        }

        .counter-icon{
            display: block;
            width: 5rem;
            height: 5rem;
            padding: 0.9rem 0.9rem;
            margin: 0 auto;
        }
    }

    @keyframes text {

        0%,
        90%,
        100% {
            width: 0px;
        }

        90%,
        100% {
            width: 100px;
        }
    }

    .animate-heading::before {
        content: "";
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        width: 3px;
        height: 100%;
        animation: cursorAnimate 0.8s steps(9) infinite;
    }

    .web-images {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .web-item {
        margin-inline-start: 10px;
        align-items: center;
        padding: 30px 0px;
        justify-content: center;
        border: 1px solid #e9edf4;
        border-radius: 7px;

        img {
            width: 50px;
            height: 50px;
        }

        h4 {
            margin-block-start: 10px;
            margin-inline-start: 10px;
            font-weight: bold;
            color: #74829c;
            font-size: 18px;
        }
    }

    .faqs .card:hover {
        box-shadow: none;
    }

    .faqs .card-header {
        background: none;
        padding: 1.0rem 1.0rem;
        display: flex;
        align-items: center;
        margin-block-end: 0;
        background-color: transparent;
        letter-spacing: 0.2px;
        width: 100% !important;
        height: 100% !important;
    }

    .faqs .card-title {
        font-size: 0.9rem;
        font-weight: 500;
        margin-block-end: 1.5rem;
    }

    .faqs .card-options a:not(.btn) {
        margin-inline-start: 0.5rem;
        color: #76839a;
        display: inline-block;
        min-width: 1rem;
    }

    .faqs .card-collapsed> :not(.card-header):not(.card-status) {
        display: none;
    }

    .faqs .card-header a:hover {
        color: #000;
    }

    .faqs .card .card-body {
        padding: 3.5rem 3.5rem 1.5rem 3.5rem;
        transition: 0.3s ease all;
    }

    .faqs .card .card-header {
        transition: 0.3s ease all;
    }

    .faqs i {
        transform: rotate(180deg);
        transition: all 0.3s;
        color: white;
        border-radius: 50px;
        width: 15px;
        height: 15px;
    }

    .faqs .card {
        padding: 1rem;
        transition: 0.3s ease all;
    }

    .faqs.demo-screen-demo #grid p {
        margin-block-start: 0;
        font-size: 14px;
        line-height: inherit;
        font-family: inherit;
        text-align: start;
    }

    .faqs .card-collapsed> :not(.card-header):not(.card-status) {
        display: none;
    }

    .social-profile-buttons {
        position: fixed;
        z-index: 9;
        inset-inline-end: 0;
        top: 20%;

        .social-icon {
            display: grid;
            margin-block-end: 15px;
            border-radius: 5px;
        }
    }

    .top.sticky.stickyClass {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        border-block-end: 1px solid #ebedfa;
        background: $white;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    }

    .faqs {
        .card.highlights {
            
                .card-header {
                    border-inline-start: 0;
                }

                .card-body {
                    border-inline-start: 0;
                }
            
        }
    }

    .faqs {
        .card {
            &.bg-primary-transparent {
                .card-header {
                    border-inline-start: 5px solid $primary;

                    .card-options-collapse {
                        i {
                            background-color: $primary;
                        }
                    }
                }

                .card-body {
                    border-inline-start: 5px solid $primary;
                    border-radius: 0 0 7px 7px;
                }
            }

            &.bg-secondary-transparent {
                .card-header {
                    border-inline-start: 5px solid $secondary;

                    .card-options-collapse {
                        i {
                            background-color: $secondary;
                        }
                    }
                }

                .card-body {
                    border-inline-start: 5px solid $secondary;
                    border-radius: 0 0 7px 7px;
                }
            }

            &.bg-success-transparent {
                .card-header {
                    border-inline-start: 5px solid $success;

                    .card-options-collapse {
                        i {
                            background-color: $success;
                        }
                    }
                }

                .card-body {
                    border-inline-start: 5px solid $success;
                    border-radius: 0 0 7px 7px;
                }
            }

            &.bg-danger-transparent {
                .card-header {
                    border-inline-start: 5px solid $danger;

                    .card-options-collapse {
                        i {
                            background-color: $danger;
                        }
                    }
                }

                .card-body {
                    border-inline-start: 5px solid $danger;
                    border-radius: 0 0 7px 7px;
                }
            }

            &.bg-warning-transparent {
                .card-header {
                    border-inline-start: 5px solid $warning;

                    .card-options-collapse {
                        i {
                            background-color: $warning;
                        }
                    }
                }

                .card-body {
                    border-inline-start: 5px solid $warning;
                    border-radius: 0 0 7px 7px;
                }
            }

            &.bg-info-transparent {
                .card-header {
                    border-inline-start: 5px solid $info;

                    .card-options-collapse {
                        i {
                            background-color: $info;
                        }
                    }
                }

                .card-body {
                    border-inline-start: 5px solid $info;
                    border-radius: 0 0 7px 7px;
                }
            }
        }
    }

    h2 {
        font-size: 30px;
    }

    .buynow-landing {
        top: 70px;
        border-radius: 25px;
        z-index: 1;
        box-shadow: 0px 16px 32px rgb(33 33 33 / 15%);

        &::before {
            border-radius: 25px;
        }
    }

    .card-shadow {
        box-shadow: 0 4px 25px 0 rgba(168, 180, 208, 0.1);
    }

    .footer-social-list a:hover {
        color: $white;
    }

    .user-social-detail .social-profile {
        height: 35px;
        width: 35px;
        background-color: $white;
        border: 1px solid $primary;
        font-size: 14px;
        line-height: 2.3;
    }

    .collapse.navbar-collapse {
        background-color: transparent;
    }

    .features.main-features-1.card:hover {
        border: 1px solid $primary  !important;
        box-shadow: 0px 16px 32px rgb(33 33 33 / 15%);
    }

    .features.main-features-2.card:hover {
        border: 1px solid $warning  !important;
    }

    .features.main-features-3.card:hover {
        border: 1px solid $success  !important;
    }

    .features.main-features-4.card:hover {
        border: 1px solid $info  !important;
    }

    .features.main-features-5.card:hover {
        border: 1px solid $secondary  !important;
    }

    .features.main-features-6.card:hover {
        border: 1px solid $danger  !important;
    }

    .features.main-features-7.card:hover {
        border: 1px solid $primary  !important;
    }

    .features.main-features-8.card:hover {
        border: 1px solid $warning  !important;
    }

    .bg-primary-transparent {
        background-color: $primary-2 !important;
    }

    @media (min-width: 1276px) {

        &.horizontalmenu .hor-header .container,
        &.horizontalmenu .horizontalmenu .container,
        &.horizontalmenu .main-content.hor-content .container {
            max-width: 1140px !important;
        }

        &.horizontalmenu .main-demo .container {
            max-width: 1140px !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1275.98px) {

        &.horizontalmenu .hor-header .container,
        &.horizontalmenu .horizontalmenu .container,
        &.horizontalmenu .main-content.hor-content .container {
            max-width: 960px !important;
        }

        &.horizontalmenu {
            .side-menu>li>a {
                margin: -3px 0px !important;
                color: $default-color;
                padding: 16px 12px !important;
            }

            .side-menu .slide .side-menu__item.active {
                font-weight: 500;
            }

            .fixed-header {
                position: relative;
            }
        }
    }

    .navbar .navbar-brand {
        padding: 8px 15px;
    }

    &.horizontalmenu .slide {
        margin: 0;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .landing-top-header .landing-app-sidebar .container {
            max-width: 960px !important;
        }
    }

    @media (min-width: 768px) {
        .hor-content .container {
            max-width: 720px !important;
        }
    }

    @media (min-width: 576px) {
        .hor-content .container {
            max-width: 540px;
        }
    }

    @media (max-width: 576px) {
        .slick-next {
            inset-inline-end: -10px !important;
        }

        .demo-screen-headline h1 {
            font-size: 35px;
        }
    }

    .demo-footer {
        @media (min-width: 576px) {
            .container {
                max-width: 540px;
            }
        }

        @media (min-width: 768px) {
            .container {
                max-width: 720px !important;
            }
        }

        @media (min-width: 992px) and (max-width: 1275.98px) {
            .container {
                max-width: 960px !important;
            }
        }

        @media (min-width: 1276px) {
            .container {
                max-width: 1140px !important;
            }
        }
    }

    @media (max-width: 991px) {
        .navresponsive-toggler {
            padding: 3px;
        }
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-duration: 1s;
    animation-delay: 1s;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@keyframes run {
    0% {
        top: -50%;
    }

    100% {
        top: 110%;
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 3px;
    }
}

.slick-slide {
    margin: 20px 20px;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    inset-inline-start: 0;

    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float:$float-left;

    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float:$float-right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-slide img {
    width: 75px;
    height: 75px;
    padding: 14px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

@media (min-width: 992px) {
    .landing-page {
        .navbar-toggler {
            display: none;
        }

        .side-header.hor-header {
            display: none;
        }

        .main-sidemenu.container {
            max-width: 960px !important;
        }

        .top.sticky.sticky-pin .landing-app-sidebar {
            border-block-end: 1px solid $white-2;
            background-color: $primary  !important;
            box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
            z-index: 999;
        }

        &.horizontalmenu .side-menu>li>a {
            margin: -3px 5px !important;
            font-weight: 500;
            padding: 16px 16px;
            color: $white-7;
        }

        &.horizontalmenu .side-menu>li>a.active {
            color: $white;
            border-radius: 20%;
        }
        &.horizontalmenu .side-menu>li>a:hover{
            border-radius: 20%;
        }

        &.horizontalmenu .side-menu {
            display: flex;
            overflow: hidden;
            padding: 0;
            margin-block-end: 0;
        }
    }
}

@media (min-width: 768px) {
    .landing-page .main-sidemenu.container {
        max-width: 720px !important;
    }
}

.team-members {
    position: absolute;
    border: 2px solid $white;
    top: -35px;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 16px 32px rgb(33 33 33 / 15%);
}

.bg-image-style {
    background-image: url(../landing/images/bg3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    position: relative;
    border-radius: 0px;
}

.landing-page {

    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }

    .slick-prev {

        &:hover,
        &:focus {
            color: transparent;
            outline: none;
            background: transparent;
        }
    }

    .slick-next {

        &:hover,
        &:focus {
            color: transparent;
            outline: none;
            background: transparent;
        }
    }

    .slick-prev {

        &:hover:before,
        &:focus:before {
            opacity: 1;
        }
    }

    .slick-next {

        &:hover:before,
        &:focus:before {
            opacity: 1;
        }
    }

    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
        opacity: 0.25;
    }

    .slick-prev:before,
    .slick-next:before {
        font-family: "feather";
        font-size: 20px;
        padding: 10px;
        line-height: 1;
        opacity: 0.75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border: 1px solid rgb(255 255 255 / 30%);
        background: rgb(255 255 255 / 20%);
        box-shadow: 0px 16px 32px rgb(0 0 0 / 20%);
        border-radius: 50px;
    }

    .slick-prev {
        inset-inline-start: -25px;
    }

    [dir="rtl"] .slick-prev {
        inset-inline-end: -25px;
        inset-inline-start: auto;
    }

    .slick-prev:before {
        content: "\e92e";
    }

    [dir="rtl"] .slick-prev:before {
        content: "â†’";
    }

    .slick-next {
        inset-inline-end: -3px;
    }

    [dir="rtl"] .slick-next {
        inset-inline-end: auto;
        inset-inline-start: -3px;
    }

    .slick-next:before {
        content: "\e92f";
    }

    [dir="rtl"] .slick-next:before {
        content: "<";
    }

    .section {
        padding: 1.5rem 0;
        background-position: center center;
        background-size: cover;
    }

    .dotes {
        content: "";
        margin-block-start: 3px;
        box-shadow: 0px 4px 16px rgb(135 96 251 / 51%);
        background: $primary;
        width: 19px;
        height: 15px;
        border-radius: 57%;
        color: $white;
    }

    .tab_wrapper.left_side>ul {
        width: 60%;
        float:$float-left;
        border-block-end: 1pxpx solid #ccc;
        font-size: 12px;
    }

    .tab_wrapper.left_side .content_wrapper {
        width: 350px;
        height: 264px;
        border: 1px solid $primary;
        float:$float-left;
        border-radius: 0px 5px 5px 0px;
    }

    .tab_wrapper .content_wrapper .tab_content {
        display: none;
        padding: 10px;
    }

    .tab_wrapper .content_wrapper {
        top: 0px;
    }
}

.landing-page.horizontalmenu .slide {
    list-style: none;
}

.landing-page .main-navbar .nav-link.with-sub::after {
    display: none;
}

.landing-page {
    .main-logo {
        margin: 0;
    }

    .landing-top-header .header-brand-img.desktop-logo {
        display: none;
    }

    .main-sidemenu {
        .header-brand-img {
            height: 2rem;
        }
    }

    .side-header .header-brand-img.desktop-logo-dark {
        display: block;
    }

    .desktop-dark, .desktop-logo-dark, .main-logo.dark-theme, .dark-logo-2, .dark-logo-1 {
        display: inherit;
    }


    @media (min-width: 992px) {
        .main-content {
            padding-block-start: 0;
        }
    }
}

@media (min-width: 1200px) {
    .landing-page {

        &.horizontalmenu .main-navbar.hor-menu .container,
        &.horizontalmenu .main-content.hor-content .container,
        &.horizontalmenu .container {
            max-width: 1140px !important;
        }
    }
}

@media (min-width: 992px) {
    .landing-page {

        &.horizontalmenu .main-navbar.hor-menu .container,
        &.horizontalmenu .main-content.hor-content .container,
        &.horizontalmenu .container {
            max-width: 960px;
        }
        &.horizontalmenu .sticky-pin {
            position: fixed !important;
            top: 0 !important;
            width: 100%;
            z-index: 99;
            .main-sidemenu.navbar .main-logo .header-brand-img{
                &.desktop-logo-dark{
                    display: none;
                }
                &.desktop-logo{
                    display: block;
                }

            } 
            .side-menu__label{
                color: $white-8;
            }
            .side-menu__item.active, .side-menu__item:hover, .side-menu__item:focus{
                .side-menu__label{
                background: transparent;
                color: $white !important;
                }
            }
        }
    }
}

.testimonial-owl-landing {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    color: $white;
    position: relative;
    border-radius: 0px;
    background: url(../img/photos/35.jpg) center center;
    padding: 50px 0;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset-inline-start: 0;
        inset-inline-end: 0;
        display: block;
        z-index: 0;
        background-color: $primary-8 !important;
        top: 0;
    }
}
.main-features.main-features-1 svg g,.main-features.main-features-1 svg path,.main-features.main-features-1 svg rect{
    fill: $primary;
}
.features-icon svg path, .features-icon svg circle,.features-icon svg rect{
    fill: $primary;

}

.student-img img {
    position: absolute;
    bottom: -190px;
    inset-inline-end: 0;
}

.services-statistics.landing-statistics {
    .reveal {
        margin-block-end: 0;
        background: transparent;
    }
}

@media screen and (max-width: 991px) {
    .landing-page .main-logo {
        display: none;
    }

    .landing-top-header::before {
        height: 100%;
    }

    .student-img img {
        display: none;
    }

    .services-statistics.landing-statistics .reveal {
        margin-block-end: 10px;
    }

    .landing-page .side-header .header-brand-img.desktop-logo-dark {
        display: none;
    }
    .landing-page .side-header .header-brand-img.desktop-logo {
        display: none;
    }
    .dark-theme.landing-page .header-brand-img.desktop-logo {
        display: none !important;
    }
}

.landing-page {
    #back-to-top i {
        line-height: 2.5;
    }

    .landing-card-header {
        color: $primary;
    }

    ul {
        li {
            list-style: none;
        }
    }
}

.counter-body {
    padding-block-start: 15px;
}

.hexagon-wrapper {
    margin: auto;
    display: flex;
    text-align: initial;
    width: 200px;
    cursor: pointer;
}

.hexagon {
    position: relative;
    width: 20%;
    height: 35%;
    margin: 0 auto;
    color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    transition: 0.5s;
    border-radius: 5px;
    transition: 0.5s;
}

.hexagon i {
    z-index: 1;
    margin: auto;
    font-size: 30px;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    padding: 18px;
}

.hexagon:before,
.hexagon:after {
    position: absolute;
    content: "";
    background: inherit;
    height: 100%;
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    transform-origin: center;
}

.hexagon:before {
    transform: rotateZ(60deg);
    border-radius: 5px;
    transition: 0.5s;
}

.hexagon:after {
    transform: rotateZ(-60deg);
    border-radius: 5px;
    transition: 0.5s;
}

.main-features-1 {
    .hexagon {
        background: $primary;
    }
}

.main-features-2 {
    .hexagon {
        background: $warning;
    }
}

.main-features-3 {
    .hexagon {
        background: $success;
    }
}

.main-features-4 {
    .hexagon {
        background: $info;
    }
}

.main-features-5 {
    .hexagon {
        background: $secondary;
    }
}

.main-features-6 {
    .hexagon {
        background: $danger;
    }
}

.main-features-7 {
    .hexagon {
        background: $primary;
    }
}

.main-features-8 {
    .hexagon {
        background: $warning;
    }
}


@media (max-width: 377px) {
    .landing-page .working-section .working-svg {
        transform: scale(0.85) translateX(-35px);
    }
}

.landing-page .working-section .working-svg-container {
    svg {
        width: 300px;
        height: 300px;
    }
}

.landing-page {

    #grid .card.card-collapsed .card-controls .expand {
        display: block;
    }

    #grid .card:not(.card-collapsed) .card-controls .expand {
        display: none;
    }

    #grid .card.card-collapsed .card-controls .shrink {
        display: none;
    }

    #grid .card:not(.card-collapsed) .card-controls .shrink {
        display: block;
    }

    #grid .card .card-controls {
        position: absolute;
        inset-inline-end: 12px;
    }

    #Clients {
        .landing-title {
            background: linear-gradient(to right, transparent, $white, transparent);
            position: absolute;
            text-align: center;
            justify-content: center;
            margin: 0 auto;
            align-items: center;
            inset-inline-end: 0;
            inset-inline-start: 0;
        }
    }

    #Contact {
        .services-statistics .counter-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            padding: 18px;
            border-radius: 10px;
            i {
                font-size: 17px;
            }
        }
    }

    .top-footer a {
        color: $dark-color;
        line-height: 2;
    }

    .demo-footer {
        .btn-icon {
            display: initial;
        }
    }

    .main-footer {
        font-size: 14px;
        font-weight: 400;
    }
    .side-menu .slide{
        border-block-end: 0px;
    }
}

@media (min-width: 992px) {

    .landing-page .demo-screen-skin,
    .landing-page .demo-screen-rtl {
        padding: 60px 0;
    }
}

@media (max-width: 991.98px) {
    .landing-page {
        .landing-app-sidebar {
            background-color: #fff !important;
        }

        .landing-app-sidebar {
            position: fixed;
            top: 0;
            bottom: 0;
            inset-inline-start: 0;
            color: #8a98ac;
            z-index: 99;
            background: #fff !important;
            transition: all ease 0.2s;
            box-shadow: 10px 10px 10px $black-1;
        }

        &.horizontalmenu {
            padding-block-start: 0px;
        }

        &.horizontalmenu .landing-app-sidebar {
            width: 270px;
        }

        &.app.sidenav-toggled .landing-app-sidebar {
            inset-inline-start: 0;
        }

        &.horizontalmenu.app .landing-app-sidebar {
            top: 60px;
        }

        &.horizontalmenu .landing-app-sidebar {
            inset-inline-start: -300px;
        }

        .side-menu__item {
            padding-inline-start: 5px;
        }

        .side-menu__item {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            padding: 0.625rem 1.5rem;
            font-size: 14px;
            font-weight: 400;
            -webkit-transition: all ease 0.3s;
            -o-transition: all ease 0.3s;
            transition: all ease 0.3s;
            color: $default-color;
            margin: 0px 0px 2px 0px;
        }

        .side-menu__label {
            white-space: nowrap;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            position: relative;
            top: 1px;
            line-height: inherit;
        }

        .side-menu__item.active .side-menu__label {
            color: $primary  !important;
        }

        .side-menu__item.active .side-menu__label::before,
        .landing-page .side-menu__item:hover .side-menu__label::before {
            transform: scale(1, 1) !important;
            transform-origin: left center !important;
        }

        .side-menu__item .side-menu__label::before {
            content: "";
            position: absolute;
            transform-origin: right center;
            bottom: -4px;
            width: 70%;
            height: 0.2rem;
            transform: scale(0, 1);
            border-radius: 50px;
            background: $primary;
            transition: transform 0.3s cubic-bezier(0.5, 0.7, 0.8, 1);
        }
        .side-menu{
            padding: 0;
        }
    }

}
.svg-icons-lg{
    width: 35px;
    height: 35px;
}
.border-left-primary{
  border-inline-start: 5px solid $primary !important;
}
.jq-star-svg {
    padding-inline-start: 3px;
     width: 100%; 
     height: 100%; 
}
.landing-page {
    .main-content:after{
        background: transparent;
    }
    .side-menu__item.active, .side-menu__item:hover, .side-menu__item:focus{
        border-radius: 10px;
    }
    .bg-white-2 {
       background: #f4f9ff ; 
    }
    .pricing-tabs .pri-tabs-heading {
        padding: 15px;
        text-align: center;
        margin: 0 auto;
        display: inline-table;
    }
    .pricing-tabs .nav-price li a {
        padding: 10px 20px 10px 20px;
        color: $default-color;
        background: $white;
        border: 1px solid $border;
        border-radius: 4px;
        margin-inline: 3px;
    }
    .pricing-tabs .nav-price li .active{
        background: $primary !important;
        color: $white;
        border-color: $primary;
    }

}
body.dark-theme.landing-page{
    background:$dark-theme !important;
}
.dark-theme.landing-page {
    .header-brand-img.desktop-logo-dark{
        display: none;
    }
    .header-brand-img.desktop-logo{
      display: block;
    }
    .bg-white-2{
        background-color: $black-2;
    }
    .card{
        box-shadow: none;
    }
    .card.features.main-features{
        border-color: $dark-border !important;
    }
    .pricing-tabs .nav-price li a{
        color:$white;
        background: $dark-theme;
       border-color: $dark-border;
    }
    .faqs .card-header a:hover{
        color:$white;

    }
    .shape.bottom-footer-shape svg path{
      fill: $black-2;
    }
    @media (max-width: 991.98px) {
        .side-header.hor-header{
            background: $dark-theme;
            border-bottom-color: $dark-border;
        }
        .landing-app-sidebar{
            background: $dark-theme !important;
    
        }
        .responsive-logo{
            .mobile-logo{
                    display: none;
            }
            .mobile-logo-dark{
                    display: block;
            }

        } 
        .main-header-menu-icon span::before, .main-header-menu-icon span::after,.main-header-menu-icon span{
            background-color:$white-7;
        }
    }
 }

.header-setting-icon {
    display: block;
    font-size: 20px;
    position: relative;
    line-height: 0;
    outline: none;
    margin: 0 7px;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    text-align: center;
    .nav-link {
        color: white;
    }
}

.landing-page .main-sidemenu {
    margin-block-start: 0;
}
.w-sm {
    min-width: 110px;
}

.main-header-menu-icon {
    margin-inline: 10px;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    span::before {
        top: -6px;
    }
    span::after {
        bottom: -6px;
    }
    span {
        display: block;
        position: relative;
        width: 15px;
        height: 2px;
        background-color: #383858;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        &::before, &::after {
            content: "";
            position: absolute;
            inset-inline-start: 0;
            width: 20px;
            height: 2px;
            background-color: #383858;
            border-radius: 3px;
            transition: all 0.2s ease-in-out;
        }
    }
}
@media (max-width: 991.98px) {
    .dark-theme.landing-page .main-header-menu-icon span::before, .dark-theme.landing-page .main-header-menu-icon span::after, .dark-theme.landing-page .main-header-menu-icon span {
        background-color: rgba(255, 255, 255, 0.7);
    }
}
/* ###### Landing End  ###### */