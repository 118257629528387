/* ###### Profile  ###### */
.main-header-profile .main-img-user {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    inset-inline-end: 6px;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    bottom: 3px;
    display: block !important;
    top: 35px;
	
  }

  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  text-align: center;
}

.main-profile-menu .profile-user img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-inline-start: 0;
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.main-profile-overview .main-img-user::after {
  display: none !important;
}

/* ###### 7.8 Profile  ###### */

.main-content-profile {
  flex: 1;
}

@media (max-width: 991.98px) {
  .main-content-profile {
    .container, .container-fluid {
      display: block;
    }
  }
}

.main-content-left-profile {
  padding-inline-start: 0;
  padding-inline-end: 0;
  display: block;
  border-block-end: 1px solid $border;
  padding-block-end: 25px;
  width: auto;
}

@media (min-width: 992px) {
  .main-content-left-profile {
    width: 270px;
    padding-inline-end: 20px;
    padding-block-end: 0;
    border-inline-end: 1px solid $border;
    border-block-end: 0;
  }
}

@media (min-width: 1200px) {
  .main-content-left-profile {
    padding-inline-end: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1024) {
  .main-profile-social-list .media-body {
    margin-inline-start: 12px;
  }
}

.main-profile-overview {
  .main-img-user {
    width: 120px;
    height: 120px;
    margin-block-end: 20px;
  }

  .btn-icon-list {
    .btn, .sp-container button {
      border-radius: 100%;
    }
  }
}

.sp-container .main-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.main-profile-name {
  color: $gray-900;
  font-weight: 700;
  font-size: 20px;
  margin-block-end: 2px;
}

.main-profile-name-text {
  color: $gray-600;
  font-size: 13px;
  margin-block-end: 0;
}

.main-profile-bio {
  font-size: 13px;
  margin-block-end: 20px;
}

.main-profile-social-list {
  .media {
    align-items: center;

    + .media {
      margin-block-start: 20px;
    }
  }

  .media-icon {
    font-size: 34px;
    width: 30px;
    line-height: 0;
  }

  .media-body {
    margin-inline-start: 20px;

    span {
      display: block;
      font-size: 12px;
    }

    a {
      font-size: 13px;
    }
  }
}

.main-content-body-profile {
  .nav {
    flex-direction: column;
    padding: 20px 20px 20px 0;
    border-block-end: 1px solid $border;
    margin-block-end:1.3rem;
  }

  .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: $gray-800;

    &.active {
      font-weight: 700;
      color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}

@media (min-width: 576px) {
  .main-content-body-profile .nav {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .main-content-body-profile .nav {
    padding-inline-start: 20px;
  }
}

@media (min-width: 1200px) {
  .main-content-body-profile .nav {
    padding-inline-start: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    top: 37px;
    bottom: -20px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 2px;
    width: auto;
  }
}

@media (max-width: 575px) {
  .main-content-body-profile .main-nav-line .nav-link {
    padding-block: 3px;
  }
}


.main-profile-body {
  padding: 15px 0 0;
}

@media (min-width: 576px) {
  .main-profile-body {
    padding-block-start: 20px;
  }
}

@media (min-width: 992px) {
  .main-profile-body {
    padding: 25px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .main-profile-body {
    padding-inline-start: 25px;
  }
}

.main-profile-view-chart {
  position: relative;
  width: calc(100% - 10px);
  height: 200px;
}

@media (min-width: 375px) {
  .main-profile-view-chart {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .main-profile-view-chart {
    height: 250px;
  }
}

.main-profile-view-info {
  position: absolute;
  top: 0;
  inset-inline-start: 0;

  h6 {
    font-size: 32px;
    font-weight: 500;
    color: $gray-900;
    margin-block-end: 0;
  }

  span {
    font-size: 12px;
    color: $pink;
    margin-inline-start: 5px;
  }

  p {
    font-size: 13px;
    margin-block-end: 0;
  }
}

.main-traffic-detail-item {
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-block-end: 7px;

    > span {
      &:first-child {
        color: $gray-600;
      }

      &:last-child {
        font-size: 11px;
        font-weight: 700;
        color: $gray-900;

        span {
          color: $gray-600;
          font-weight: 400;
        }
      }
    }
  }

  + .main-traffic-detail-item {
    margin-block-start: 25px;
  }

  .progress {
    height: 8px;
  }
}

.main-profile-work-list {
  .media + .media {
    margin-block-start: 25px;
  }

  .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: $white;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
  }

  .media-body {
    margin-inline-start: 20px;

    h6 {
      color: $gray-900;
      font-weight: 500;
      margin-block-end: 2px;
    }

    span {
      display: block;
      margin-block-end: 5px;
    }

    p {
      margin-block-end: 0;
      font-size: 12px;
      color: $gray-600;
    }
  }
}

.main-profile-contact-list {
  .media {
    align-items: center;

    + .media {
      margin-block-start: 25px;
    }
  }

  .media-icon {
    line-height: 0;
    font-size: 36px;
    width: 35px;
    text-align: center;
  }

  .media-body {
    margin-inline-start: 25px;

    span {
      font-size: 12px;
      color: $gray-600;
      display: block;
      line-height: 1.3;
    }

    div {
      font-weight: 500;
      color: $gray-900;
    }
  }
}

@media (max-width: 1134px) {
  .main-profile-body .media.profile-footer {
    display: block !important;

    .media-body {
      padding: 3px 0 15px 0;
    }
  }
}

@media (max-width: 578px) {
  .main-profile-menu .profile-user img {
    margin-inline-start: 0;
  }
}

.main-profile-work-list .media-logo, .main-profile-contact-list .media-icon, .main-profile-social-list .media-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: $white;
  font-size: 21px;
  position: relative;
  top: 2px;
  border-radius: 100%;
}

.profile-user .profile-edit {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  inset-inline-end: 0;
  background: #d5d4e0;
  margin: 0 auto;
  text-align: center;
}

.main-profile-body .card-header {
  padding-block-start: 0;
  padding-inline-start: 0;
}

.drop-down-profile {
  position: relative;
}

.profile-footer a {
  width: 34px;
  height: 34px;
  background: #efeff5;
  color: $black;
  margin: 1px auto;
  text-align: center;
  line-height: 34px;
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
}

.drop-down-profile span.assigned-task {
  position: absolute;
  font-size: 12px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: $white;
  top: 0;
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
  inset-inline-end: 0;
}

@media (max-width: 575px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    inset-inline-start: 0px;
  }
}

@media (max-width: 767.98px) {
  .main-profile-menu .profile-user img {
    margin-inline-start: 0 !important;
  }
}

.profile-name {
  margin-block-start: 8px;
}

.profile.navtab-custom {
  padding: 11px 0 10px 0;

  li a {
    padding: 13px 8px;
    color: $black;
    background: transparent;
    margin-inline-end: 7px;
    border-start-start-radius: 7px;
    border-start-end-radius: 7px;
    border: 1px solid #e3e6f0;
    border-block-end: 0;
    font-size: 13px;
  }

  .active a, a.active {
    background: $gray-100;
    border-block-end: 0;
    color: $black;
  }
}

.ga-border {
  height: 3px;
  width: 40px;
  background-color: $primary;
  margin: 10px auto 5px auto;
}

.todo-widget-header {
  .dropdown-menu-start {
    box-shadow: none;
  }
  
  .main-header-profile {
    padding: 8px 2px;
    span {
      color: inherit;
    }
  }
}
/* ###### Profile End ###### */