/* ###### Error  ###### */

.main-error-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  h1 {
    line-height: 1;
    font-size: 150px;
    font-weight: 700;
    color: $gray-900;
  }

  h2 {
    font-weight: 500;
    color: $gray-800;
    letter-spacing: -0.5px;
    margin-block-end: 15px;
  }

  h6 {
    margin-block-end: 40px;
    font-size: 14px;
  }

  .btn,
  .sp-container button {
    border-width: 2px;
    font-weight: 700;
  }
}

.sp-container .main-error-wrapper button {
  border-width: 2px;
  font-weight: 700;
}

/* ###### 7.9 Signin  ###### */

.main-signin-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-card-signin {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  position: relative;
}

.main-card-signup {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: $white-5;
  position: relative;
}

@media (max-width: 767px) {
  .main-card-signin {
    display: block !important;
    padding: 0;
  }

  .page-signin-style:before {
    display: none;
  }
}

@media (min-width: 480px) {
  .main-card-signin {
    padding: 0;
    max-width: 750px;

    &.construction {
      max-width: 1000px;
    }
  }

  .main-card-signup {
    border: 1px solid $border;
    padding: 0;
    max-width: 850px;
  }

  .sign-up-body {
    padding: 2.6rem !important;
  }
}

.main-signin-header {
  h2 {
    font-weight: 500;
    color: $primary;
    letter-spacing: -1px;
  }

  h4 {
    font-weight: 500;
    color: $default-color;
    margin-block-end: 25px;
    font-size: 16px;
  }

  label {
    color: #a5a0b1;
  }

  .form-control {
    color: $default-color;
    font-weight: 500;
    border-width: 1px;
    border-color: $border;

    &:focus {
      border-color: $gray-400;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: #a5a0b1;
    }
  }
}

.page-signin-style:before {
  content: "";
  background: rgba(109, 110, 243, 0.92);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  inset-inline-start: 0;
  top: 0;
}

.authentication-pages {
  position: relative;
  z-index: 99;
}

.main-card-signin {
  box-shadow: none;
}

.main-signin-header {
  .btn,
  .sp-container button {
    margin-block-start: 25px;
  }
}

.sp-container .main-signin-header button {
  margin-block-start: 25px;
}

.main-signin-footer {
  p {
    color: #a5a0b1;

    &:first-child {
      margin-block-end: 5px;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }

  a {
    color: $default-color;
    font-weight: 700;

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

/* ###### 7.10 Signup  ###### */

.main-signup-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(244, 245, 248, 0.2);
}

@media (min-width: 992px) {
  .main-signup-wrapper {
    justify-content: flex-end;
  }

  .page-signin-style {
    height: auto !important;
    height: auto !important;
  }
}

.page-signin-style {
  width: 100%;
  height: 100%;
  background: url(../img/media/4.jpg);
  inset-inline-start: 0;
  top: 0;
  bottom: 0;
  position: relative;
}

.main-column-signup-left {
  flex: 1;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;

  > div {
    max-width: 500px;
  }

  .typcn {
    font-size: 80px;
    margin-block-end: 50px;

    &::before {
      width: auto;
    }
  }

  .main-logo {
    font-size: 40px;
    line-height: 1;
  }

  h5 {
    color: $default-color;
    font-weight: 500;
    font-size: 18px;
    margin-block-end: 25px;
  }

  p {
    line-height: 1.7;
    margin-block-end: 25px;
  }

  .btn,
  .sp-container button {
    border-width: 2px;
    padding-inline-start: 25px;
    padding-inline-end: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
  }
}

@media (min-width: 576px) {
  .main-column-signup-left {
    padding: 40px;
  }
}

@media (min-width: 992px) {
  .main-column-signup-left {
    display: flex;
  }
}

.sp-container .main-column-signup-left button {
  border-width: 2px;
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 700;
  text-transform: uppercase;
  height: 38px;
}

.main-column-signup {
  background-color: $white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .main-column-signup {
    padding: 40px;
    width: 500px;
  }
}

@media (min-width: 992px) {
  .main-column-signup {
    border-inline-start: 1px solid $border;
  }
}

.main-signup-header {
  h2 {
    font-weight: 500;
    color: $primary;
    letter-spacing: -1px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    color: $default-color;
    margin-block-end: 25px;
  }

  label {
    color: #a5a0b1;
  }

  .form-control {
    color: $default-color;
    font-weight: 500;
    border-width: 1px;
    border-color: $border;

    &:focus {
      border-color: $gray-400;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: #a5a0b1;
    }
  }

  > .btn {
    margin-block-start: 25px;
  }
}

.sp-container .main-signup-header > button {
  margin-block-start: 25px;
}

.main-signup-header .row {
  margin-block-start: 20px;

  > div {
    .btn i,
    .sp-container button i {
      font-size: 15px;
      line-height: 0;
      margin-inline-end: 5px;
    }
  }
}

.sp-container .main-signup-header .row > div button i {
  font-size: 15px;
  line-height: 0;
  margin-inline-end: 5px;
}

.main-signup-header .row > div:first-child {
  .btn,
  .sp-container button {
    background-color: #4267b2;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button {
  background-color: #4267b2;
  color: $white;
}

.main-signup-header .row > div:first-child {
  .btn:hover,
  .sp-container button:hover {
    background-color: #375694;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button:hover {
  background-color: #375694;
  color: $white;
}

.main-signup-header .row > div:first-child {
  .btn:focus,
  .sp-container button:focus {
    background-color: #375694;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button:focus {
  background-color: #375694;
  color: $white;
}

.main-signup-footer {
  p {
    color: #a5a0b1;
    margin-block-end: 0;
  }

  a {
    color: $default-color;
    font-weight: 700;

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.construction {
  .form-control {
    height: 39px;
    padding: 0.375rem 1rem;
  }

  .btn.btn-icon {
    border: 0;
    text-align: center;
    padding: 0;
    background: 0 0;
    font-size: 20px;
    color: $black-9;
    margin: 0 5px;
    border-radius: 3px;
    .btn-inner--icon {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $primary-3;

    }
  }
}
.login,
.image {
  min-height: 100vh;
}

.construction {
  .form-control {
    border-radius: 4px;
    width: 100%;
  }

  .input-group-text,
  .input-group-text {
    position: absolute;
    inset-inline-end: 1px;
  }

  .input-group > {
    .input-group-text > .btn {
      border-start-start-radius: 0;
      border-start-end-radius: 4px;
      border-end-start-radius: 0;
      border-end-end-radius: 4px;

      margin: 0;
      padding: 0 25px;
      height: 38px;
      line-height: 39px;
      float: $float-right;
      border: 0;
      font-size: 14px;
      color: $white;
      box-shadow: none;
      position: relative;
      overflow: hidden;
      z-index: 99;
    }

    .form-control:not(:last-child),
    .form-select:not(:last-child) {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
    }
  }
}

@media (max-width: 767px) {
  .main-signup-header,
  .main-card-signin {
    padding: 1.5rem;
    border: 1px solid #e1e5ef;
    border-radius: 6px;
  }

  .error-page {
    height: 70%;
    width: 70%;
  }
}

@media (min-width: 768px) {
  .main-card-signin {
    border: 0 !important;
    box-shadow: none !important;
  }

  .main-signin-header {
    box-shadow: none !important;
  }

  .error-page {
    height: 30%;
    width: 30%;
  }
}
.error-page1 .demo-icon {
  position: absolute;
  inset-inline-start: auto;
  inset-inline-end: 60px;
  top: 60px;
  z-index: 1;
  svg {
    fill: $default-color;
    width: 25px;
    height: 25px;
  }
}
.wrapper-1{
  min-height: 100vh;
}
.error-2{
  .page {
    background-color: $primary-3 !important;
  }
}

/* ###### Error End ###### */
