/* ###### Toggle ###### */
.main-toggle {
  width: 60px;
  height: 25px;
  background-color:$gray-400;
  padding: 2px;
  position: relative;
  overflow: hidden;
  margin-block: 3px;

  span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    inset-inline-start: 3px;
    display: block;
    width: 20px;
    background-color: $white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &::before, &::after {
      position: absolute;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: $white;
      top: 2px;
      line-height: 1.38;
    }

    &::before {
      content: 'on';
      inset-inline-start: -25px;
    }

    &::after {
      content: 'off';
      inset-inline-end: -29px;
    }
  }

  &.on {
    background-color: $primary;

    span {
      inset-inline-start: 37px;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-toggle span {
    transition: none;
  }
}

.main-toggle-secondary.on {
  background-color: $primary;
}

.main-toggle-success.on {
  background-color: $success;
}

.main-toggle-dark.on {
  background-color: $gray-900;
}

.main-toggle-group-demo {
  display: flex;
  flex-wrap: wrap;

  .main-toggle {
    margin-inline: 5px;
  }
}
/* ###### Toggle End ###### */