/* ###### Widgets ###### */
.widget-user {
  .widget-user-header {
    padding: 20px;
    height: 120px;
  }

  .widget-user-username {
    margin-block-start: 0;
    margin-block-end: 5px;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 1px 1px $black-2;
    color: $white;
  }

  .widget-user-desc {
    margin-block-start: 0;
    color: $white;
  }

  .widget-user-image {
    position: absolute;
    top: 65px;
    inset-inline-start: 50%;
    margin-inline-start: -45px;

    > img {
      width: 90px;
      height: auto;
      border: 3px solid $white;
    }
  }

  .user-wideget-footer {
    padding-block-start: 30px;
  }
}

.user-wideget-footer {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-block-start: 1px solid #f4f4f4;
  padding: 10px;
  background-color: $white;
}

.user-wideget .border-end {
  border-inline-end: 1px solid #f4f4f4;
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;

  > {
    .description-header {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: 16px;
    }

    .description-text {
      text-transform: uppercase;
      color: #a3b1c9;
    }
  }
}
/* ###### Widgets End ###### */