/* ###### Listgroup   ###### */
.list-group {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-block-end: 0;
}

.list-group-item-action {
  width: 100%;
  color: #434f63;
  text-align: inherit;

  &:hover,
  &:focus {
    z-index: 1;
    color: $gray-700;
    text-decoration: none;
    background-color: $gray-100;
  }

  &:active {
    color: $default-color;
    background-color: $gray-200;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-block-end: -1px;
  background-color: $white;
  border: 1px solid rgb(231, 235, 243);

  &:first-child {
    border-start-start-radius: 3px;
    border-start-end-radius: 3px;
  }

  &:last-child {
    margin-block-end: 0;
    border-end-end-radius: 3px;
    border-end-start-radius: 3px;
  }

  &.disabled,
  &:disabled {
    color: $gray-600;
    pointer-events: none;
    background-color: $white;
  }

  &.active {
    z-index: 1;
    color: $white;
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.list-group-horizontal {
  flex-direction: row;

  .list-group-item {
    margin-inline-end: -1px;
    margin-block-end: 0;

    &:first-child {
      border-start-start-radius: 3px;
      border-end-start-radius: 3px;
      border-start-end-radius: 0;
    }

    &:last-child {
      margin-inline-end: 0;
      border-start-end-radius: 3px;
      border-end-end-radius: 3px;
      border-end-start-radius: 0;
    }
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;

    .list-group-item {
      margin-inline-end: -1px;
      margin-block-end: 0;

      &:first-child {
        border-start-start-radius: 3px;
        border-end-start-radius: 3px;
        border-start-end-radius: 0;
      }

      &:last-child {
        margin-inline-end: 0;
        border-start-end-radius: 3px;
        border-end-end-radius: 3px;
        border-end-start-radius: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;

    .list-group-item {
      margin-inline-end: -1px;
      margin-block-end: 0;

      &:first-child {
        border-start-start-radius: 3px;
        border-end-start-radius: 3px;
        border-start-end-radius: 0;
      }

      &:last-child {
        margin-inline-end: 0;
        border-start-end-radius: 3px;
        border-end-end-radius: 3px;
        border-end-start-radius: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;

    .list-group-item {
      margin-inline-end: -1px;
      margin-block-end: 0;

      &:first-child {
        border-start-start-radius: 3px;
        border-end-start-radius: 3px;
        border-start-end-radius: 0;
      }

      &:last-child {
        margin-inline-end: 0;
        border-start-end-radius: 3px;
        border-end-end-radius: 3px;
        border-end-start-radius: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;

    .list-group-item {
      margin-inline-end: -1px;
      margin-block-end: 0;

      &:first-child {
        border-start-start-radius: 3px;
        border-end-start-radius: 3px;
        border-start-end-radius: 0;
      }

      &:last-child {
        margin-inline-end: 0;
        border-start-end-radius: 3px;
        border-end-end-radius: 3px;
        border-end-start-radius: 0;
      }
    }
  }
}

.list-group-flush {
  .list-group-item {
    border-inline-end: 0;
    border-inline-start: 0;
    border-radius: 0;

    &:last-child {
      margin-block-end: -1px;
    }
  }

  &:first-child .list-group-item:first-child {
    border-block-start: 0 !important;
  }

  &:last-child .list-group-item:last-child {
    margin-block-end: 0;
    border-block-end: 0;
  }
}
.list-group-item + .list-group-item {
   border-top-width: 1px; 
}

.list-group-item-primary {
  color: #1b3585;
  background-color: #c6d4ff;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #1b3585;
      background-color: #adc1ff;
    }

    &.active {
      color: $white;
      background-color: #1b3585;
      border-color: #1b3585;
    }
  }
}

.list-group-item-secondary {
  color: #3f4654;
  background-color: #d9dde5;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #3f4654;
      background-color: #cacfdb;
    }

    &.active {
      color: $white;
      background-color: #3f4654;
      border-color: #3f4654;
    }
  }
}

.list-group-item-success {
  color: #1f5c01;
  background-color: #c8e9b8;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #1f5c01;
      background-color: #b9e3a5;
    }

    &.active {
      color: $white;
      background-color: #1f5c01;
      border-color: #1f5c01;
    }
  }
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #0c5460;
      background-color: #abdde5;
    }

    &.active {
      color: $white;
      background-color: #0c5460;
      border-color: #0c5460;
    }
  }
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #856404;
      background-color: #ffe8a1;
    }

    &.active {
      color: $white;
      background-color: #856404;
      border-color: #856404;
    }
  }
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #721c24;
      background-color: #f1b0b7;
    }

    &.active {
      color: $white;
      background-color: #721c24;
      border-color: #721c24;
    }
  }
}

.list-group-item-light {
  color: #7f7f81;
  background-color: #fcfcfd;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #7f7f81;
      background-color: #ededf3;
    }

    &.active {
      color: $white;
      background-color: #7f7f81;
      border-color: #7f7f81;
    }
  }
}

.list-group-item-dark {
  color: #1f2533;
  background-color: #c8ccd3;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #1f2533;
      background-color: #babfc8;
    }

    &.active {
      color: $white;
      background-color: #1f2533;
      border-color: #1f2533;
    }
  }
}
.listgroup-example ul {
  ul {
    list-style-type: square;
    margin: 10px 0 0 0;
    padding-inline-start: 2rem;
  }

  list-style-type: none;

  li {
    padding: 5px 5px;
  }
}

.listgroup-example2 ul {
  ul {
    list-style-type: circle;
    margin: 10px 0 0 0;
    padding-inline-start: 2rem;
  }

  list-style-type: none;

  li {
    padding: 5px 5px;
  }
}

.users-list-group {
  .btn-icon {
    i {
      line-height: 34px;
      line-height: 34px;
    }

    width: 34px;
    height: 34px;
  }

  .list-group-item {
    padding: 0.85rem 1.25rem;
  }
}

.list-style-1 {
  list-style: inherit;
  padding-inline-start: inherit;
}

.list-style-1 ul {
  list-style: inherit;
  padding-inline-start: 30px;
}

.list-style-1 li {
  line-height: 30px;
}

.list-style2 {
  list-style: decimal;
  padding-inline-start: inherit;
}

.list-style2 ul {
  list-style: decimal;
  padding-inline-start: 30px;
}

.list-style2 li {
  line-height: 30px;
}

.list-style3 {
  list-style: circle !important;
  padding-inline-start: inherit;
}

.list-style3 ul {
  list-style: decimal;
  padding-inline-start: 30px;
}

.list-style3 li {
  line-height: 30px;
}

.list-style4 {
  list-style: lower-alpha;
  padding-inline-start: inherit;
}

.list-style4 ul {
  list-style: decimal;
  padding-inline-start: 30px;
}

.list-style4 li {
  line-height: 30px;
}

.list-style5 {
  list-style: square;
  padding-inline-start: inherit;
}

.list-style5 ul {
  list-style: decimal;
  padding-inline-start: 30px;
}

.list-style5 li {
  line-height: 30px;
}

.list-style6 {
  list-style: lower-roman;
  padding-inline-start: inherit;
}

.list-style6 ul {
  list-style: decimal;
  padding-inline-start: 30px;
}

.list-style6 li {
  line-height: 30px;
}

.order-list li {
  line-height: 30px;
}

.paragraph-list {
  list-style: lower-roman;
  padding-inline-start: inherit;
  list-style-type: inline;
  padding-inline-start: 20px;
}

.list-group-transparent {
  .list-group-item {
    background: none !important;
    border: 0 !important;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    color: $default-color;
    background-color: transparent;
    border-color: transparent;
    &:last-child {
      padding-block-end: 1rem;
    }
    &.active, &:hover {
      color: $primary;
    }
  }
}

/* ###### Listgroup End  ###### */
