/* ###### Tables ###### */

.table {
  color: $gray-800;

  thead {
    th,
    td {
      color: #37374e;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-bottom-width: 1px;
      border-top-width: 0;
      padding: 0.75rem;
    }
  }

  tbody tr {
    background-color: $white-5;

    th {
      font-weight: 400;
    }
  }

  th,
  td {
    padding: 9px 15px;
    line-height: 1.462;
  }
}

.table > :not(:last-child) > :last-child > * {
  border-block-end: $gray-200;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(238, 238, 247, 0.5);
}

.table-bordered thead {
  th,
  td {
    border-top-width: 1px;
    padding-block-start: 7px;
    padding-block-end: 7px;
    background-color: $white-5;
  }
}

.table {
  margin-block-end: 1rem;
  color: $default-color;

  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-block-start: 1px solid $border;
  }

  thead th {
    vertical-align: bottom;
  }

  tbody + tbody {
    border-block-start: 2px solid $border;
  }
}

.table-sm {
  th,
  td {
    padding: 0.3rem;
  }
}

.table-bordered {
  border: 1px solid $border;

  th,
  td {
    border: 1px solid $border;
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $gray-100;
}

.table-hover tbody tr:hover {
  color: $default-color;
  background-color: rgb(239, 243, 247);
}

.table.dataTable.no-footer {
  border-block-end: 1px solid $border !important;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  > .table-bordered {
    border: 0;
  }
}

@media print {
  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: $white !important;
    }
  }

  .table-bordered {
    th,
    td {
      border: 1px solid $border !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: $gray-300;
    }
  }

  .table .thead-dark th {
    color: inherit;
    border-color: $gray-300;
  }
}

.table.table-clean {
  td {
    .value {
      font-size: 0.9rem;
      line-height: 1.6;
      font-weight: 500;
    }

    .sub-value {
      font-size: 0.72rem;
      color: $gray-600;
    }
  }

  tr:first-child td {
    border-block-start: none;
  }

  td {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    border-block-start-color: $black-05;
  }

  th,
  td {
    padding: 7px 20px !important;
  }
}
.dt-buttons.btn-group {
  position: absolute;
  top: 0;
  inset-inline-start: 156px;
}
.dt-button.dropdown-item.buttons-columnVisibility {
  padding: 8px 49px;
}
.btn-primary.data-table-btn {
  position: absolute;
  inset-inline-start: 185px;
  z-index: 9;
}
@media (max-width: 1363px) {
  .dt-buttons.btn-group {
    position: relative;
    top: 0;
    inset-inline-start: 0;
  }
}
.table > :not(:first-child) {
  border-block-start: 0px solid transparent;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: $background;
  color: $default-color;
}
.table.dtr-details {
  border: 1px solid $border;
}
.product-details {
  .table-bordered > :not(caption) > * {
    border-block-end: 1px solid $border;
  }
}
.country-table {
  &.table-responsive > .table-bordered {
    border: 1px solid $border;
  }
}
.table {
  --bs-table-hover-bg: transparent;
}

.table> :not(caption)>*>* {
  background-color: transparent;
}

.table-warning {
  background: $warning;
  color: white;
  th, td, thead th, tbody+tbody {
    border-color: rgba($warning, 0.15) !important;
  }
  &.table> :not(:first-child) {
    border-block-start: 1px solid $warning !important;
  }
  tbody tr {
    background-color: transparent !important;
  }
  thead th {
    background: $warning !important;
    color: white;
  }
  &.table-bordered> :not(caption)>* {
    border-block-end-color: rgba($warning, 0.15) !important;
  }
}
.table-warning {
  background-color: rgba($warning, 0.3);
}

.table-primary {
  background: $primary-3;
  color: white;
  th, td, thead th, tbody+tbody {
    border-color: $primary-1 !important;
  }
  &.table> :not(:first-child) {
    border-block-start: 1px solid $primary !important;
  }
  tbody tr {
    background-color: transparent !important;
  }
  thead th {
    background: $primary !important;
    color: white;
  }
  &.table-bordered> :not(caption)>* {
    border-block-end-color: $primary-1 !important;
  }

}
.table-secondary {
  background: $secondary;
  color: white;
  th, td, thead th, tbody+tbody {
    border-color: rgba($secondary, 0.15) !important;
  }
  &.table> :not(:first-child) {
    border-block-start: 1px solid $secondary !important;
  }
  tbody tr {
    background-color: transparent !important;
  }
  thead th {
    background: $secondary !important;
    color: white;
  }
  &.table-bordered> :not(caption)>* {
    border-block-end-color: rgba($secondary, 0.15) !important;
  }
}
.table-secondary {
  background-color: rgba($secondary, 0.3);
}

.table-success {
  background: $success;
  color: white;
  th, td, thead th, tbody+tbody {
    border-color: rgba($success, 0.15) !important;
  }
  &.table> :not(:first-child) {
    border-block-start: 1px solid $success !important;
  }
  tbody tr {
    background-color: transparent !important;
  }
  thead th {
    background: $success !important;
    color: white;
  }
  &.table-bordered> :not(caption)>* {
    border-block-end-color: rgba($success, 0.15) !important;
  }
}
.table-success {
  background-color: rgba($success, 0.3);
}

.table-danger {
  background: $danger;
  color: white;
  th, td, thead th, tbody+tbody {
    border-color: rgba($danger, 0.15) !important;
  }
  &.table> :not(:first-child) {
    border-block-start: 1px solid $danger !important;
  }
  tbody tr {
    background-color: transparent !important;
  }
  thead th {
    background: $danger !important;
    color: white;
  }
  &.table-bordered> :not(caption)>* {
    border-block-end-color: rgba($danger, 0.15) !important;
  }
}
.table-danger {
  background-color: rgba($danger, 0.3);
}

.table-info {
  background: $info;
  color: white;
  th, td, thead th, tbody+tbody {
    border-color: rgba($info, 0.15) !important;
  }
  &.table> :not(:first-child) {
    border-block-start: 1px solid $info !important;
  }
  tbody tr {
    background-color: transparent !important;
  }
  thead th {
    background: $info !important;
    color: white;
  }
  &.table-bordered> :not(caption)>* {
    border-block-end-color: rgba($info, 0.15) !important;
  }
}
.table-info {
  background-color: rgba($info, 0.3);
}

/* ###### Tables End ###### */