/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   VALEX - HTML5 Bootstrap Admin
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/*
Accordions
Alerts
Badges
Breadcrumbs
Buttons
Card
Carousel
Custom
Dropdown
Forms
Grid
Input-group
Modals
Navigation
Pagination
Popover
Progress
Tables
Tabs
Toast
Tooltip
Checkbox
Countdown
Custom-control
Custom-styles
Ecommerce
I.E
Image
Landing
Listgroup
Navbar
P-scroll
Radio
Rating
Tags
Timeline
Toggle
Widgets
Double-menu-styles
Footer
Header-styles
Header
Horizontal-menu
Iconbar
Main-content
Menu-styles
Profile
Rtl
Sidebar
Sidemenu
Style-Dark
Switcher-styles
Chart
Datatable
Date-picker
Date-time-picker
Jquery-Steps
Jqvmap
Parsely
Rangeslider
Select2
Spectrum
Plugins
Calendar
Chat
Contacts
Error
Invoice
Mail
Background
Border
Height
Margin
Opacity
Padding
Position
Typography
Width
*/

/* ############### FONTS IMPORT ############### */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");

/* ######## BOOTSTRAP ######## */
@import "variables";
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/badge";
@import "bootstrap/breadcrumbs";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/custom";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/modals";
@import "bootstrap/nav";
@import "bootstrap/paginations";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/tables";
@import "bootstrap/toast";
@import "bootstrap/tooltip";
@import "bootstrap/tabs";

/*  ######## CUSTOM-STYLES ######## */
@import "custom/checkbox";
@import "custom/countdown";
@import "custom/custom-control";
@import "custom/custom-styles";
@import "custom/ecommerce";
@import "custom/Ie";
@import "custom/image";
@import "custom/landing";
@import "custom/listgroup";
@import "custom/navbar";
@import "custom/p-scroll";
@import "custom/radio";
@import "custom/ratting";
@import "custom/tags";
@import "custom/timeline";
@import "custom/toggle";
@import "custom/widgets";

/* ######## LAYOUT-STYLES ######## */
@import "layout/double-menu-styles";
@import "layout/footer";
@import "layout/header";
@import "layout/horizontal-menu";
@import "layout/iconbar";
@import "layout/maincontent";
@import "layout/profile";
@import "layout/rtl";
@import "layout/sidebar";
@import "layout/sidemenu";
@import "layout/style-dark";
@import "layout/menu-styles";
@import "layout/header-styles";
@import "layout/switcher-styles";

/* ######## LIB-STYLES ######## */
@import "lib/chart";
@import "lib/datatable";
@import "lib/datepicker";
@import "lib/datetimepicker";
@import "lib/jquerysteps";
@import "lib/jqvmap";
@import "lib/parsely";
@import "lib/rangesliders";
@import "lib/select2";
@import "lib/spectrum";

/* ######## TEMP-STYLES ######## */
@import "temp/calendar";
@import "temp/chat";
@import "temp/contact";
@import "temp/error";
@import "temp/invoice";
@import "temp/mail";

/* ######## UTILITIES-STYLES ######## */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/opacity";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";
