/* ######### Tabs #########*/
.tabs-style-1 .main-nav-line .nav-link {
  &.active::before {
    bottom: 0;
  }

  padding: 10px 18px 10px 18px;
  background: transparent;
}

.tabs-style-2 .main-nav-line .nav-link {
  &.active {
    &::before {
      display: none;
    }

    background: transparent;
    color: $primary;
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }

  padding: 0.7rem 2rem;
  background: $gray-100;
}
.tabs-style-2 .main-nav-line .nav-link {
  padding: 0.7rem 2rem;
  background: $gray-100;
  border: 1px solid $border;
  border-block-end: 0;
}
.tabs-style-2 li .nav-link.active {
  background: none;
  box-shadow: inset 0 3px 1px $primary;
  color: $primary;
  border-block-start: 0;
}
.tabs-style-3 {

  .nav.panel-tabs li a {
    padding: 10px 24px 10px 24px;
    background: $gray-100;
    border-radius: 20px;
    margin: 0 5px 5px 0;
    text-align: center;
    display: block;
    color: $default-color;
  }

  .tabs-menu-body {
    margin-block-start: 20px;
    border: 1px solid $border;
  }

  .nav.panel-tabs li a.active {
    background: $primary !important;
    color: $white !important;
  }
}

.tabs-style-4 {
  .nav.panel-tabs li {
    display: block;
    width: 100%;
  }

  .tabs-menu-body {
    padding: 20px;
    border: 1px solid #e3e7f3;
    border-radius: 4px;
  }

  .nav.panel-tabs li a {
    padding: 10px 18px 10px 18px;
    background: $gray-100;
    border-radius: 4px;
    margin: 0 0 16px 0;
    text-align: center;
    display: block;
    color: $default-color;
    width: 100%;

    &.active {
      background: $primary !important;
      color: $white !important;
    }
  }
}

@media (min-width: 768px) {
  .tabs-style-4 .tab-menu-heading {
    width: 200px;
  }
}

.panel-primary.tabs-style-1 .main-nav-line .nav-link.active::before {
  top: auto;
  bottom: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  height: 2px;
  width: auto;
}

.tab_wrapper {
  &.right_side {
    > ul {
      width: 25%;
      float: $float-right;
      padding-inline-start: 0;
      border-block-end: 1px solid #f0f2f7;
    }

    > ul li {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 12px 18px;
      border-width: 1px;
      border-inline-end: 0;
      border-inline-start: 0;
      text-align: end;
      list-style-type: none;

      &:after {
        inset-inline-end: 0;
        inset-inline-start: inherit;
        top: 0;
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        background: #f0f2f7;
      }

      &.active {
        border-color: #f0f2f7;

        &:after {
          inset-inline-end: 0;
          inset-inline-start: inherit;
          top: 0;
          width: 5px;
          height: 100%;
          content: "";
        }

        &:before {
          inset-inline-start: -2px;
          inset-inline-end: inherit;
          position: absolute;
          top: 0;
          content: "";
          width: 2px;
          height: 100%;
          background: $white;
        }
      }
    }

    .content_wrapper {
      width: 75%;
      float: $float-left;
      border: 1px solid #f0f2f7;
    }

    .controller {
      top: -50px;
    }
  }

  > ul li.active {
    border-color: $primary;
    background: $primary;
    color: $white;
  }

  display: inline-block;
  width: 100%;
  position: relative;

  * {
    box-sizing: border-box;
  }

  > ul {
    clear: both;
    display: inline-block;
    width: 100%;
    border-block-end: 1px solid rgba(0, 40, 100, 0.12);
    margin-block-end: 0;

    li {
      float: $float-left;
      cursor: pointer;
      font-weight: 500;
      padding: 8px 18px;
      text-transform: uppercase;
      border: 1px solid #f0f2f7;
      font-size: 1.05em;
      border-block-end: 0;
      position: relative;
      border-block-start: 3px solid #f0f2f7;
      margin: 0 5px 0 0;

      &.active:after {
        content: "";
        position: absolute;
        bottom: -1px;
        height: 1px;
        inset-inline-start: 0;
        background: $white;
        width: 100%;
      }
    }
  }

  .controller {
    display: block;
    overflow: hidden;
    clear: both;
    position: absolute;
    top: 0;
    inset-inline-end: 0;

    span {
      padding: 8px 22px;
      text-transform: uppercase;
      display: inline-block;

      &.next {
        float: $float-right;
        margin-inline-start: 10px;
      }
    }
  }

  .content_wrapper {
    float: $float-left;
    width: 100%;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-block-start: 0;
    box-sizing: border-box;

    .tab_content {
      display: none;
      padding: 15px 20px;


      .error {
        color: red;
      }
    }

    .accordian_header {
      display: none;
      padding: 10px 20px;
      text-transform: uppercase;
      font-weight: bold;
      border-block-start: 1px solid #f0f2f7;
      border-block-end: 1px solid $white;
      position: relative;
      cursor: pointer;

      &.active {
        border: 1px solid #f0f2f7;

        &:after {
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          content: "";
          width: 5px;
          height: 100%;
        }
      }

      .arrow {
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        border-radius: 2px;
        transform: rotate(-135deg);
        background: $white;
        border-block-start: 3px solid #cdd2e0;
        border-inline-start: 3px solid #cdd2e0;
      }

      &.active .arrow {
        transform: rotate(45deg);
        margin-block-start: 5px;
      }
    }
  }

  &.left_side {
    > ul {
      width: 25%;
      float: $float-left;
      padding-inline-start: 0;
      border-block-end: 1px solid #f0f2f7;

      li {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 12px 18px;
        border-width: 1px;
        border-inline-end: 0;
        border-inline-start: 0;
        list-style-type: none;

        &:after {
          inset-inline-start: 0;
          top: 0;
          content: "";
          position: absolute;
          width: 5px;
          height: 100%;
          background: #f0f2f7;
        }

        &.active {
          border-color: #f0f2f7;

          &:after {
            inset-inline-start: 0;
            top: 0;
            width: 5px;
            height: 100%;
          }

          &:before {
            inset-inline-end: -1px;
            position: absolute;
            top: 0;
            content: "";
            width: 2px;
            height: 100%;
            background: $white;
          }
        }
      }
    }

    .content_wrapper {
      width: 75%;
      border: 1px solid #f0f2f7;
      float: $float-left;
    }

    .controller {
      top: -50px;
    }
  }

  &.right_side {
    > ul {
      width: 25%;
      float: $float-right;
      border-block-end: 1px solid #f0f2f7;

      li {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 12px 18px;
        border-width: 1px;
        border-inline-end: 0;
        border-inline-start: 0;
        text-align: end;

        &:after {
          inset-inline-end: 0;
          inset-inline-start: inherit;
          top: 0;
          content: "";
          position: absolute;
          width: 5px;
          height: 100%;
          background: #f0f2f7;
        }

        &.active {
          border-color: #f0f2f7;

          &:after {
            inset-inline-end: 0;
            inset-inline-start: inherit;
            top: 0;
            width: 5px;
            height: 100%;
            content: "";
          }

          &:before {
            inset-inline-start: -2px;
            inset-inline-end: inherit;
            position: absolute;
            top: 0;
            content: "";
            width: 2px;
            height: 100%;
            background: $white;
          }
        }
      }
    }

    .content_wrapper {
      width: 75%;
      float: $float-left;
      border: 1px solid #f0f2f7;
    }

    .controller {
      top: -50px;
    }
  }

  &.accordion {
    .content_wrapper {
      border-color: #f0f2f7;

      .accordian_header {
        display: block;
      }
    }

    > ul {
      display: none;
    }
  }

  .active_tab {
    display: none;
    position: relative;
    background: $black;
    padding: 12px;
    text-align: center;
    padding-inline-end: 50px;
    color: $white;
    cursor: pointer;
    border-radius: 30px;

    .arrow {
      width: 30px;
      height: 30px;
      overflow: hidden;
      line-height: 30px;
      text-align: center;
      background: $white;
      position: absolute;
      inset-inline-end: 6px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);

      &:after {
        content: "";
        width: 10px;
        display: inline-block;
        height: 10px;
        border-inline-end: 3px solid $black;
        border-block-end: 3px solid $black;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        margin-inline-start: -6px;
        margin-block-start: -8px;
      }
    }

    .text {
      text-indent: 39px;
      display: block;
    }
  }
}

@media only screen and (max-width: 768px) {
  .tab_wrapper {
    > ul {
      display: none;
    }

    .content_wrapper {
      border: 0;

      .accordian_header {
        display: block;
      }
    }

    &.left_side .content_wrapper,
    &.right_side .content_wrapper {
      width: 100%;
      border-block-start: 0;
    }

    .controller {
      display: none;
    }

    &.show-as-dropdown {
      > .tab_list {
        border: 0;
        box-shadow: 0 0 8px #f0f2f7;
        position: absolute;
        z-index: 3;
        background: $white;
        border-radius: 5px;
        margin-block-start: 15px;

        &:before {
          content: "";
          position: absolute;
          top: -6px;
          inset-inline-start: 50%;
          width: 14px;
          height: 14px;
          background: $white;
          transform: rotate(45deg);
          box-shadow: -3px -4px 5px #efefef;
          margin-inline-start: -7px;
        }

        li {
          border: 0;
          border-block-end: 1px solid #f0f2f7;

          &.active:after {
            display: none;
          }
        }
      }

      .active_tab {
        display: block;
      }

      > {
        .tab_list,
        .content_wrapper > .accordian_header {
          display: none;
        }

        .tab_list li {
          display: block;
          float: none;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .tab_wrapper.show-as-dropdown > .tab_list {
    display: block !important;
    overflow: hidden;
  }
}
.tab_wrapper .content_wrapper .accordian_header.active {
  color: $primary;

  &:after {
    background: $primary;
  }

  .arrow {
    transform: rotate(45deg);
    margin-block-start: 5px;
  }
}

.tabs-style-1 {
  .panel-tabs {
    border-block-end: 1px solid #e0e6ed;
  }

  .main-nav-line {
    .nav-link.active {
      color: $primary;
      border: 1px solid;
      border-color: #e0e6ed #e0e6ed $white;
    }

    .nav-item {
      margin-block-end: -1px;
    }
  }

  .panel-tabs.main-nav-line .nav-link.active::before {
    background: transparent !important;
  }
}

/* ######### Tabs End #########*/