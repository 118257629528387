/* ######  Breadcrumbs ###### */

.breadcrumb-header {
  display: flex;
  margin-block-start: 24px;
  margin-block-end: 24px;
  width: 100%;

  .breadcrumb {
    background: transparent !important;
    padding: 0;
    margin-block-end: 0;
    font-size: 12px;
  }
}

.breadcrumb-3 {
  ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
  }

  li {
    display: contents;
    text-align: center;
  }
}

.breadcrumb-4 {
  ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: end;
  }

  li {
    display: contents;
    text-align: end;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

@media (max-width: 633px) {
  .breadcrumb-header {
    display: block;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-block-end: 1rem;
  list-style: none;
  background-color: $gray-200;
  border-radius: 3px;
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-inline-start: 0.5rem;

    &::before {
      display: inline-block;
      padding-inline-end: 0.5rem !important;
      color: #c8cfdd;
      content: "\e933";
      font-family: 'feather';

    }

    &:hover::before {
      text-decoration: underline;
      text-decoration: none;
    }
  }

  a {
  color: $gray-600;
  }

  &.active {
    color: $primary;
  }
}

.breadcrumb {
  background-color: $gray-100;
}

.breadcrumb-style2{
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}

.breadcrumb-style2 .breadcrumb-item {
  font-size: 11px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: .5px;
  text-transform: uppercase;
}

.breadcrumb-style2 .breadcrumb-item a{
  color: $gray-900;
}

.breadcrumb-style2 .breadcrumb-item a {
  &:hover, &:focus {
    color: $primary;
  }
}

.breadcrumb-style2 .breadcrumb-item.active {
  color: $primary;
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  color: $gray-500;
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: '\e92f';
  font-family: 'feather';
}

.breadcrumb-item+.breadcrumb-item::before{
  float: none !important;
}

.breadcrumb-header .right-content{
  .btn-group {
    .btn:not(:last-child):not(.dropdown-toggle) {
      padding-inline-end: 0;
    }
    .btn:hover {
      background-color: $primary;
    }
  }
}
.breadcrumb-style3 {
  border-radius: 0;
    background-color: transparent;
    padding: 0;
    .breadcrumb-item {
      font-size: 11px;
      font-weight: 500;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      letter-spacing: 0.5px;
      text-transform: uppercase;
      a {
        color: #242f48;
    }
  }
  .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-inline-end: 0.5rem !important;
    color: #737f9e;
    content: "/";
  }
}

.breadcrumb-3, .breadcrumb-4 {
  .breadcrumb-style3 {
    .breadcrumb-item+.breadcrumb-item::before {
      padding-inline: 0.5rem !important;
    }
  }
}
  /* ######  Breadcrumbs End ###### */