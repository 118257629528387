/* ###### Grid ###### */

.row-sm {
  margin-inline-start: -10px;
  margin-inline-end: -10px;

  > div {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }
}

.row-xs {
  margin-inline-start: -5px;
  margin-inline-end: -5px;

  > div {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }
}

@media (min-width: 576px) {
  .row-xs--sm {
    margin-inline-start: -5px;
    margin-inline-end: -5px;

    > div {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }
  }

  .row-sm--sm {
    margin-inline-start: -10px;
    margin-inline-end: -10px;

    > div {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }

  .row--sm {
    margin-inline-start: -15px;
    margin-inline-end: -15px;

    > div {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }
  }
}

@media (min-width: 768px) {
  .row-xs--md {
    margin-inline-start: -5px;
    margin-inline-end: -5px;

    > div {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }
  }

  .row-sm--md {
    margin-inline-start: -10px;
    margin-inline-end: -10px;

    > div {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }

  .row--md {
    margin-inline-start: -15px;
    margin-inline-end: -15px;

    > div {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }
  }
}

@media (min-width: 992px) {
  .row-xs--lg {
    margin-inline-start: -5px;
    margin-inline-end: -5px;

    > div {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }
  }

  .row-sm--lg {
    margin-inline-start: -10px;
    margin-inline-end: -10px;

    > div {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }

  .row--lg {
    margin-inline-start: -15px;
    margin-inline-end: -15px;

    > div {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }
  }
}

@media (min-width: 1200px) {
  .row-xs--xl {
    margin-inline-start: -5px;
    margin-inline-end: -5px;

    > div {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }
  }

  .row-sm--xl {
    margin-inline-start: -10px;
    margin-inline-end: -10px;

    > div {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }

  .row--xl {
    margin-inline-start: -15px;
    margin-inline-end: -15px;

    > div {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }
  }
}

/*#### Grid End ####*/