/* ###### Typography ###### */

.tx-black {
    font-weight: 900;
  }
  
  .tx-bold {
    font-weight: 700;
  }
  
  .tx-semibold {
    font-weight: 600;
  }
  
  .tx-medium {
    font-weight: 500;
  }
  
  .tx-normal {
    font-weight: 400;
  }
  
  .tx-light {
    font-weight: 300;
  }
  
  .tx-thin {
    font-weight: 200;
  }
  
  .tx-xthin {
    font-weight: 100;
  }
  
  .tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .tx-roboto {
    font-family: 'Roboto', sans-serif;
  }
  
  .tx-white {
    color: $white;
  }
  
  .tx-facebook {
    color: #3b5998;
  }
  
  .tx-pinterest {
    color: #cd2029;
  }
  
  .tx-google-plus {
    color: #d34836;
  }
  
  .tx-twitter {
    color: #429cd6;
  }
  
  .tx-black {
    color: $black;
  }
  
  .tx-primary {
    color: $primary;
  }
  
  .tx-success {
    color: $success;
  }
  
  .tx-warning {
    color: $warning;
  }
  
  .tx-danger {
    color: $danger;
  }
  
  .tx-info {
    color: $info;
  }
  
  .tx-inverse {
    color: $gray-900;
  }
  
  .tx-teal {
    color: $teal;
  }
  
  .tx-dark {
    color: $gray-900;
  }
  
  .tx-indigo {
    color: $primary;
  }
  
  .tx-purple {
    color: $purple;
  }
  
  .tx-orange {
    color: $orange;
  }
  
  .tx-pink {
    color: $pink;
  }
  
  .tx-gray-100 {
    color: $gray-100;
  }
  
  .tx-gray-200 {
    color: $gray-200;
  }
  
  .tx-gray-300 {
    color: $gray-300;
  }
  
  .tx-gray-400 {
    color:$gray-400;
  }
  
  .tx-gray-500 {
    color: $gray-500;
  }
  
  .tx-gray-600 {
    color: $gray-600;
  }
  
  .tx-gray-700 {
    color: $gray-700;
  }
  
  .tx-gray-800 {
    color: $gray-800;
  }
  
  .tx-gray-900 {
    color: $gray-900;
  }
  
  .tx-white-2 {
    color: $white-2;
  }
  
  .tx-white-3 {
    color: $white-3;
  }
  
  .tx-white-4 {
    color: $white-4;
  }
  
  .tx-white-5 {
    color: $white-5;
  }
  
  .tx-white-6 {
    color: $white-6;
  }
  
  .tx-white-7 {
    color: $white-7;
  }
  
  .tx-white-8 {
    color: $white-8;
  }
  
  .tx-spacing-1 {
    letter-spacing: 0.5px;
  }
  
  .tx-spacing-2 {
    letter-spacing: 1px;
  }
  
  .tx-spacing-3 {
    letter-spacing: 1.5px;
  }
  
  .tx-spacing-4 {
    letter-spacing: 2px;
  }
  
  .tx-spacing-5 {
    letter-spacing: 2.5px;
  }
  
  .tx-spacing-6 {
    letter-spacing: 3px;
  }
  
  .tx-spacing-7 {
    letter-spacing: 3.5px;
  }
  
  .tx-spacing-8 {
    letter-spacing: 4px;
  }
  
  .tx-spacing--1 {
    letter-spacing: -0.5px;
  }
  
  .tx-spacing--2 {
    letter-spacing: -1px;
  }
  
  .tx-spacing--3 {
    letter-spacing: -1.5px;
  }
  
  .tx-spacing--4 {
    letter-spacing: -2px;
  }
  
  .tx-spacing--5 {
    letter-spacing: -2.5px;
  }
  
  .tx-spacing--6 {
    letter-spacing: -3px;
  }
  
  .tx-spacing--7 {
    letter-spacing: -3.5px;
  }
  
  .tx-spacing--8 {
    letter-spacing: -4px;
  }
  
  .tx-uppercase {
    text-transform: uppercase;
  }
  
  .tx-lowercase {
    text-transform: lowercase;
  }
  
  .tx-transform-none {
    text-transform: none;
  }
  
  .tx-center {
    text-align: center;
  }
  
  .tx-end {
    text-align: end;
  }
  
  .tx-start {
    text-align: start;
  }
  
  .tx-italic {
    font-style: italic;
  }
  
  .tx-style-normal {
    font-style: normal;
  }
  
  .lh-base {
    line-height: 1.5;
  }
  
  .lh-normal {
    line-height: normal;
  }
  
  .lh-0 {
    line-height: 0;
  }
  
  .lh--1 {
    line-height: .1;
  }
  
  .lh--2 {
    line-height: .2;
  }
  
  .lh--3 {
    line-height: .3;
  }
  
  .lh--4 {
    line-height: .4;
  }
  
  .lh--5 {
    line-height: .5;
  }
  
  .lh--6 {
    line-height: .6;
  }
  
  .lh--7 {
    line-height: .7;
  }
  
  .lh--8 {
    line-height: .8;
  }
  
  .lh--9 {
    line-height: .9;
  }
  
  .lh-1 {
    line-height: 1.1;
  }
  
  .lh-2 {
    line-height: 1.2;
  }
  
  .lh-3 {
    line-height: 1.3;
  }
  
  .lh-4 {
    line-height: 1.4;
  }
  
  .lh-5 {
    line-height: 1.5;
  }
  
  .lh-6 {
    line-height: 1.6;
  }
  
  .lh-7 {
    line-height: 1.7;
  }
  
  .lh-8 {
    line-height: 1.8;
  }
  
  .lh-9 {
    line-height: 1.9;
  }
  
  .lh-10 {
    line-height: 2.0;
  }
  
  .lh-11 {
    line-height: 2.1;
  }
  
  .lh-12 {
    line-height: 2.2;
  }
  
  .lh-13 {
    line-height: 2.3;
  }
  
  .lh-14 {
    line-height: 2.4;
  }
  
  .lh-15 {
    line-height: 2.5;
  }
  
  .valign-top {
    vertical-align: top;
  }
  
  .valign-middle {
    vertical-align: middle;
  }
  
  .valign-bottom {
    vertical-align: baseline;
  }
  
  .tx-base {
    font-size: 0.875rem;
  }
  
  .tx-8 {
    font-size: 8px;
  }
  
  .tx-9 {
    font-size: 9px;
  }
  
  .tx-10 {
    font-size: 10px;
  }
  
  .tx-11 {
    font-size: 11px;
  }
  
  .tx-12 {
    font-size: 12px;
  }
  
  .tx-13 {
    font-size: 13px;
  }
  
  .tx-14 {
    font-size: 14px;
  }
  
  .tx-15 {
    font-size: 15px;
  }
  
  .tx-16 {
    font-size: 16px;
  }
  
  .tx-17 {
    font-size: 17px;
  }
  
  .tx-18 {
    font-size: 18px;
  }
  
  .tx-20 {
    font-size: 20px !important;
  }
  
  .tx-21 {
    font-size: 21px;
  }
  
  .tx-22 {
    font-size: 22px;
  }
  
  .tx-24 {
    font-size: 24px;
  }
  
  .tx-26 {
    font-size: 26px;
  }
  
  .tx-28 {
    font-size: 28px;
  }
  
  .tx-30 {
    font-size: 30px;
  }
  
  .tx-32 {
    font-size: 32px;
  }
  
  .tx-34 {
    font-size: 34px;
  }
  
  .tx-36 {
    font-size: 36px;
  }
  
  .tx-38 {
    font-size: 38px;
  }
  
  .tx-40 {
    font-size: 40px;
  }
  
  .tx-42 {
    font-size: 42px;
  }
  
  .tx-44 {
    font-size: 44px;
  }
  
  .tx-46 {
    font-size: 46px;
  }
  
  .tx-48 {
    font-size: 48px;
  }
  
  .tx-50 {
    font-size: 50px;
  }
  
  .tx-52 {
    font-size: 52px;
  }
  
  .tx-54 {
    font-size: 54px;
  }
  
  .tx-56 {
    font-size: 56px;
  }
  
  .tx-58 {
    font-size: 58px;
  }
  
  .tx-60 {
    font-size: 60px;
  }
  
  .tx-62 {
    font-size: 62px;
  }
  
  .tx-64 {
    font-size: 64px;
  }
  
  .tx-66 {
    font-size: 66px;
  }
  
  .tx-68 {
    font-size: 68px;
  }
  
  .tx-70 {
    font-size: 70px;
  }
  
  .tx-72 {
    font-size: 72px;
  }
  
  .tx-74 {
    font-size: 74px;
  }
  
  .tx-76 {
    font-size: 76px;
  }
  
  .tx-78 {
    font-size: 78px;
  }
  
  .tx-80 {
    font-size: 80px;
  }
  
  .tx-82 {
    font-size: 82px;
  }
  
  .tx-84 {
    font-size: 84px;
  }
  
  .tx-86 {
    font-size: 86px;
  }
  
  .tx-88 {
    font-size: 88px;
  }
  
  .tx-90 {
    font-size: 90px;
  }
  
  .tx-92 {
    font-size: 92px;
  }
  
  .tx-94 {
    font-size: 94px;
  }
  
  .tx-96 {
    font-size: 96px;
  }
  
  .tx-98 {
    font-size: 98px;
  }
  
  .tx-100 {
    font-size: 100px;
  }
  
  .tx-102 {
    font-size: 102px;
  }
  
  .tx-104 {
    font-size: 104px;
  }
  
  .tx-106 {
    font-size: 106px;
  }
  
  .tx-108 {
    font-size: 108px;
  }
  
  .tx-110 {
    font-size: 110px;
  }
  
  .tx-112 {
    font-size: 112px;
  }
  
  .tx-114 {
    font-size: 114px;
  }
  
  .tx-116 {
    font-size: 116px;
  }
  
  .tx-118 {
    font-size: 118px;
  }
  
  .tx-120 {
    font-size: 120px;
  }
  
  .tx-122 {
    font-size: 122px;
  }
  
  .tx-124 {
    font-size: 124px;
  }
  
  .tx-126 {
    font-size: 126px;
  }
  
  .tx-128 {
    font-size: 128px;
  }
  
  .tx-130 {
    font-size: 130px;
  }
  
  .tx-132 {
    font-size: 132px;
  }
  
  .tx-134 {
    font-size: 134px;
  }
  
  .tx-136 {
    font-size: 136px;
  }
  
  .tx-138 {
    font-size: 138px;
  }
  
  .tx-140 {
    font-size: 140px;
  }
  
  @media (min-width: 480px) {
    .tx-xs-8 {
      font-size: 8px;
    }
  
    .tx-xs-9 {
      font-size: 9px;
    }
  
    .tx-xs-10 {
      font-size: 10px;
    }
  
    .tx-xs-11 {
      font-size: 11px;
    }
  
    .tx-xs-12 {
      font-size: 12px;
    }
  
    .tx-xs-13 {
      font-size: 13px;
    }
  
    .tx-xs-14 {
      font-size: 14px;
    }
  
    .tx-xs-15 {
      font-size: 15px;
    }
  
    .tx-xs-16 {
      font-size: 16px;
    }
  
    .tx-xs-18 {
      font-size: 18px;
    }
  
    .tx-xs-20 {
      font-size: 20px;
    }
  
    .tx-xs-22 {
      font-size: 22px;
    }
  
    .tx-xs-24 {
      font-size: 24px;
    }
  
    .tx-xs-26 {
      font-size: 26px;
    }
  
    .tx-xs-28 {
      font-size: 28px;
    }
  
    .tx-xs-30 {
      font-size: 30px;
    }
  
    .tx-xs-32 {
      font-size: 32px;
    }
  
    .tx-xs-34 {
      font-size: 34px;
    }
  
    .tx-xs-36 {
      font-size: 36px;
    }
  
    .tx-xs-38 {
      font-size: 38px;
    }
  
    .tx-xs-40 {
      font-size: 40px;
    }
  
    .tx-xs-42 {
      font-size: 42px;
    }
  
    .tx-xs-44 {
      font-size: 44px;
    }
  
    .tx-xs-46 {
      font-size: 46px;
    }
  
    .tx-xs-48 {
      font-size: 48px;
    }
  
    .tx-xs-50 {
      font-size: 50px;
    }
  
    .tx-xs-52 {
      font-size: 52px;
    }
  
    .tx-xs-54 {
      font-size: 54px;
    }
  
    .tx-xs-56 {
      font-size: 56px;
    }
  
    .tx-xs-58 {
      font-size: 58px;
    }
  
    .tx-xs-60 {
      font-size: 60px;
    }
  
    .tx-xs-62 {
      font-size: 62px;
    }
  
    .tx-xs-64 {
      font-size: 64px;
    }
  
    .tx-xs-66 {
      font-size: 66px;
    }
  
    .tx-xs-68 {
      font-size: 68px;
    }
  
    .tx-xs-70 {
      font-size: 70px;
    }
  
    .tx-xs-72 {
      font-size: 72px;
    }
  
    .tx-xs-74 {
      font-size: 74px;
    }
  
    .tx-xs-76 {
      font-size: 76px;
    }
  
    .tx-xs-78 {
      font-size: 78px;
    }
  
    .tx-xs-80 {
      font-size: 80px;
    }
  
    .tx-xs-82 {
      font-size: 82px;
    }
  
    .tx-xs-84 {
      font-size: 84px;
    }
  
    .tx-xs-86 {
      font-size: 86px;
    }
  
    .tx-xs-88 {
      font-size: 88px;
    }
  
    .tx-xs-90 {
      font-size: 90px;
    }

    .tx-xs-92 {
      font-size: 92px;
    }
  
    .tx-xs-94 {
      font-size: 94px;
    }
  
    .tx-xs-96 {
      font-size: 96px;
    }
  
    .tx-xs-98 {
      font-size: 98px;
    }
  
    .tx-xs-100 {
      font-size: 100px;
    }
  
    .tx-xs-102 {
      font-size: 102px;
    }
  
    .tx-xs-104 {
      font-size: 104px;
    }
  
    .tx-xs-106 {
      font-size: 106px;
    }
  
    .tx-xs-108 {
      font-size: 108px;
    }
  
    .tx-xs-110 {
      font-size: 110px;
    }
  
    .tx-xs-112 {
      font-size: 112px;
    }
  
    .tx-xs-114 {
      font-size: 114px;
    }
  
    .tx-xs-116 {
      font-size: 116px;
    }
  
    .tx-xs-118 {
      font-size: 118px;
    }
  
    .tx-xs-120 {
      font-size: 120px;
    }
  
    .tx-xs-122 {
      font-size: 122px;
    }
  
    .tx-xs-124 {
      font-size: 124px;
    }
  
    .tx-xs-126 {
      font-size: 126px;
    }
  
    .tx-xs-128 {
      font-size: 128px;
    }
  
    .tx-xs-130 {
      font-size: 130px;
    }
  
    .tx-xs-132 {
      font-size: 132px;
    }
  
    .tx-xs-134 {
      font-size: 134px;
    }
  
    .tx-xs-136 {
      font-size: 136px;
    }
  
    .tx-xs-138 {
      font-size: 138px;
    }
  
    .tx-xs-140 {
      font-size: 140px;
    }
  
    .tx-xs-base {
      font-size: 0.875rem;
    }
  
    .tx-xs-bold {
      font-weight: 700;
    }
  
    .tx-xs-semibold {
      font-weight: 600;
    }
  
    .tx-xs-medium {
      font-weight: 500;
    }
  
    .tx-xs-normal {
      font-weight: 400;
    }
  
    .tx-xs-light {
      font-weight: 300;
    }
  
    .tx-xs-thin {
      font-weight: 200;
    }
  
    .tx-xs-xthin {
      font-weight: 100;
    }
  
    .lh-xs-0 {
      line-height: 0;
    }
  
    .lh-xs-1 {
      line-height: 1.1;
    }
  
    .lh-xs-2 {
      line-height: 1.2;
    }
  
    .lh-xs-3 {
      line-height: 1.3;
    }
  
    .lh-xs-4 {
      line-height: 1.4;
    }
  
    .lh-xs-5 {
      line-height: 1.5;
    }
  
    .lh-xs-6 {
      line-height: 1.6;
    }
  
    .lh-xs-7 {
      line-height: 1.7;
    }
  
    .lh-xs-8 {
      line-height: 1.8;
    }
  
    .lh-xs-9 {
      line-height: 1.9;
    }
  
    .lh-xs-10 {
      line-height: 2.0;
    }
  
    .lh-xs-11 {
      line-height: 2.1;
    }
  
    .lh-xs-12 {
      line-height: 2.2;
    }
  
    .lh-xs-13 {
      line-height: 2.3;
    }
  
    .lh-xs-14 {
      line-height: 2.4;
    }
  
    .lh-xs-15 {
      line-height: 2.5;
    }
  
    .tx-xs-center {
      text-align: center;
    }
  
    .tx-xs-end {
      text-align: end;
    }
  
    .tx-xs-start {
      text-align: start;
    }
  }
  
  @media (min-width: 576px) {
    .tx-sm-8 {
      font-size: 8px;
    }
  
    .tx-sm-9 {
      font-size: 9px;
    }
  
    .tx-sm-10 {
      font-size: 10px;
    }
  
    .tx-sm-11 {
      font-size: 11px;
    }
  
    .tx-sm-12 {
      font-size: 12px;
    }
  
    .tx-sm-13 {
      font-size: 13px;
    }
  
    .tx-sm-14 {
      font-size: 14px;
    }
  
    .tx-sm-15 {
      font-size: 15px;
    }
  
    .tx-sm-16 {
      font-size: 16px;
    }
  
    .tx-sm-18 {
      font-size: 18px;
    }
  
    .tx-sm-20 {
      font-size: 20px;
    }
  
    .tx-sm-22 {
      font-size: 22px;
    }
  
    .tx-sm-24 {
      font-size: 24px;
    }
  
    .tx-sm-26 {
      font-size: 26px;
    }
  
    .tx-sm-28 {
      font-size: 28px;
    }
  
    .tx-sm-30 {
      font-size: 30px;
    }
  
    .tx-sm-32 {
      font-size: 32px;
    }
  
    .tx-sm-34 {
      font-size: 34px;
    }
  
    .tx-sm-36 {
      font-size: 36px;
    }
  
    .tx-sm-38 {
      font-size: 38px;
    }
  
    .tx-sm-40 {
      font-size: 40px;
    }
  
    .tx-sm-42 {
      font-size: 42px;
    }
  
    .tx-sm-44 {
      font-size: 44px;
    }
  
    .tx-sm-46 {
      font-size: 46px;
    }
  
    .tx-sm-48 {
      font-size: 48px;
    }
  
    .tx-sm-50 {
      font-size: 50px;
    }
  
    .tx-sm-52 {
      font-size: 52px;
    }
  
    .tx-sm-54 {
      font-size: 54px;
    }
  
    .tx-sm-56 {
      font-size: 56px;
    }
  
    .tx-sm-58 {
      font-size: 58px;
    }
  
    .tx-sm-60 {
      font-size: 60px;
    }
  
    .tx-sm-62 {
      font-size: 62px;
    }
  
    .tx-sm-64 {
      font-size: 64px;
    }
  
    .tx-sm-66 {
      font-size: 66px;
    }
  
    .tx-sm-68 {
      font-size: 68px;
    }
  
    .tx-sm-70 {
      font-size: 70px;
    }
  
    .tx-sm-72 {
      font-size: 72px;
    }
  
    .tx-sm-74 {
      font-size: 74px;
    }
  
    .tx-sm-76 {
      font-size: 76px;
    }
  
    .tx-sm-78 {
      font-size: 78px;
    }
  
    .tx-sm-80 {
      font-size: 80px;
    }
  
    .tx-sm-82 {
      font-size: 82px;
    }
  
    .tx-sm-84 {
      font-size: 84px;
    }
  
    .tx-sm-86 {
      font-size: 86px;
    }
  
    .tx-sm-88 {
      font-size: 88px;
    }
  
    .tx-sm-90 {
      font-size: 90px;
    }
  
    .tx-sm-92 {
      font-size: 92px;
    }
  
    .tx-sm-94 {
      font-size: 94px;
    }
  
    .tx-sm-96 {
      font-size: 96px;
    }
  
    .tx-sm-98 {
      font-size: 98px;
    }
  
    .tx-sm-100 {
      font-size: 100px;
    }
  
    .tx-sm-102 {
      font-size: 102px;
    }
  
    .tx-sm-104 {
      font-size: 104px;
    }
  
    .tx-sm-106 {
      font-size: 106px;
    }
  
    .tx-sm-108 {
      font-size: 108px;
    }
  
    .tx-sm-110 {
      font-size: 110px;
    }
  
    .tx-sm-112 {
      font-size: 112px;
    }
  
    .tx-sm-114 {
      font-size: 114px;
    }
  
    .tx-sm-116 {
      font-size: 116px;
    }
  
    .tx-sm-118 {
      font-size: 118px;
    }
  
    .tx-sm-120 {
      font-size: 120px;
    }
  
    .tx-sm-122 {
      font-size: 122px;
    }
  
    .tx-sm-124 {
      font-size: 124px;
    }
  
    .tx-sm-126 {
      font-size: 126px;
    }
  
    .tx-sm-128 {
      font-size: 128px;
    }
  
    .tx-sm-130 {
      font-size: 130px;
    }
  
    .tx-sm-132 {
      font-size: 132px;
    }
  
    .tx-sm-134 {
      font-size: 134px;
    }
  
    .tx-sm-136 {
      font-size: 136px;
    }
  
    .tx-sm-138 {
      font-size: 138px;
    }
  
    .tx-sm-140 {
      font-size: 140px;
    }
  
    .tx-sm-base {
      font-size: 0.875rem;
    }
  
    .tx-sm-bold {
      font-weight: 700;
    }
  
    .tx-sm-semibold {
      font-weight: 600;
    }
  
    .tx-sm-medium {
      font-weight: 500;
    }
  
    .tx-sm-normal {
      font-weight: 400;
    }
  
    .tx-sm-light {
      font-weight: 300;
    }
  
    .tx-sm-thin {
      font-weight: 200;
    }
  
    .tx-sm-xthin {
      font-weight: 100;
    }
  
    .lh-sm-0 {
      line-height: 0;
    }
  
    .lh-sm-1 {
      line-height: 1.1;
    }
  
    .lh-sm-2 {
      line-height: 1.2;
    }
  
    .lh-sm-3 {
      line-height: 1.3;
    }
  
    .lh-sm-4 {
      line-height: 1.4;
    }
  
    .lh-sm-5 {
      line-height: 1.5;
    }
  
    .lh-sm-6 {
      line-height: 1.6;
    }
  
    .lh-sm-7 {
      line-height: 1.7;
    }
  
    .lh-sm-8 {
      line-height: 1.8;
    }
  
    .lh-sm-9 {
      line-height: 1.9;
    }
  
    .lh-sm-10 {
      line-height: 2.0;
    }
  
    .lh-sm-11 {
      line-height: 2.1;
    }
  
    .lh-sm-12 {
      line-height: 2.2;
    }
  
    .lh-sm-13 {
      line-height: 2.3;
    }
  
    .lh-sm-14 {
      line-height: 2.4;
    }
  
    .lh-sm-15 {
      line-height: 2.5;
    }
  
    .tx-sm-center {
      text-align: center;
    }
  
    .tx-sm-right {
      text-align: end;
    }
  
    .tx-sm-left {
      text-align: start;
    }
  }
  
  @media (min-width: 768px) {
    .tx-md-8 {
      font-size: 8px;
    }
  
    .tx-md-9 {
      font-size: 9px;
    }
  
    .tx-md-10 {
      font-size: 10px;
    }
  
    .tx-md-11 {
      font-size: 11px;
    }
  
    .tx-md-12 {
      font-size: 12px;
    }
  
    .tx-md-13 {
      font-size: 13px;
    }
  
    .tx-md-14 {
      font-size: 14px;
    }
  
    .tx-md-15 {
      font-size: 15px;
    }
  
    .tx-md-16 {
      font-size: 16px;
    }
  
    .tx-md-18 {
      font-size: 18px;
    }
  
    .tx-md-20 {
      font-size: 20px;
    }
  
    .tx-md-22 {
      font-size: 22px;
    }
  
    .tx-md-24 {
      font-size: 24px;
    }
  
    .tx-md-26 {
      font-size: 26px;
    }
  
    .tx-md-28 {
      font-size: 28px;
    }
  
    .tx-md-30 {
      font-size: 30px;
    }
  
    .tx-md-32 {
      font-size: 32px;
    }
  
    .tx-md-34 {
      font-size: 34px;
    }
  
    .tx-md-36 {
      font-size: 36px;
    }
  
    .tx-md-38 {
      font-size: 38px;
    }
  
    .tx-md-40 {
      font-size: 40px;
    }
  
    .tx-md-42 {
      font-size: 42px;
    }
  
    .tx-md-44 {
      font-size: 44px;
    }
  
    .tx-md-46 {
      font-size: 46px;
    }
  
    .tx-md-48 {
      font-size: 48px;
    }
  
    .tx-md-50 {
      font-size: 50px;
    }
  
    .tx-md-52 {
      font-size: 52px;
    }
  
    .tx-md-54 {
      font-size: 54px;
    }
  
    .tx-md-56 {
      font-size: 56px;
    }
  
    .tx-md-58 {
      font-size: 58px;
    }
  
    .tx-md-60 {
      font-size: 60px;
    }
  
    .tx-md-62 {
      font-size: 62px;
    }
  
    .tx-md-64 {
      font-size: 64px;
    }
  
    .tx-md-66 {
      font-size: 66px;
    }
  
    .tx-md-68 {
      font-size: 68px;
    }
  
    .tx-md-70 {
      font-size: 70px;
    }
  
    .tx-md-72 {
      font-size: 72px;
    }
  
    .tx-md-74 {
      font-size: 74px;
    }
  
    .tx-md-76 {
      font-size: 76px;
    }
  
    .tx-md-78 {
      font-size: 78px;
    }
  
    .tx-md-80 {
      font-size: 80px;
    }
  
    .tx-md-82 {
      font-size: 82px;
    }
  
    .tx-md-84 {
      font-size: 84px;
    }
  
    .tx-md-86 {
      font-size: 86px;
    }
  
    .tx-md-88 {
      font-size: 88px;
    }
  
    .tx-md-90 {
      font-size: 90px;
    }
  
    .tx-md-92 {
      font-size: 92px;
    }
  
    .tx-md-94 {
      font-size: 94px;
    }
  
    .tx-md-96 {
      font-size: 96px;
    }
  
    .tx-md-98 {
      font-size: 98px;
    }
  
    .tx-md-100 {
      font-size: 100px;
    }
  
    .tx-md-102 {
      font-size: 102px;
    }
  
    .tx-md-104 {
      font-size: 104px;
    }
  
    .tx-md-106 {
      font-size: 106px;
    }
  
    .tx-md-108 {
      font-size: 108px;
    }
  
    .tx-md-110 {
      font-size: 110px;
    }
  
    .tx-md-112 {
      font-size: 112px;
    }
  
    .tx-md-114 {
      font-size: 114px;
    }
  
    .tx-md-116 {
      font-size: 116px;
    }
  
    .tx-md-118 {
      font-size: 118px;
    }
  
    .tx-md-120 {
      font-size: 120px;
    }
  
    .tx-md-122 {
      font-size: 122px;
    }
  
    .tx-md-124 {
      font-size: 124px;
    }
  
    .tx-md-126 {
      font-size: 126px;
    }
  
    .tx-md-128 {
      font-size: 128px;
    }
  
    .tx-md-130 {
      font-size: 130px;
    }
  
    .tx-md-132 {
      font-size: 132px;
    }
  
    .tx-md-134 {
      font-size: 134px;
    }
  
    .tx-md-136 {
      font-size: 136px;
    }
  
    .tx-md-138 {
      font-size: 138px;
    }
  
    .tx-md-140 {
      font-size: 140px;
    }
  
    .tx-md-base {
      font-size: 0.875rem;
    }
  
    .tx-md-bold {
      font-weight: 700;
    }
  
    .tx-md-semibold {
      font-weight: 600;
    }
  
    .tx-md-medium {
      font-weight: 500;
    }
  
    .tx-md-normal {
      font-weight: 400;
    }
  
    .tx-md-light {
      font-weight: 300;
    }
  
    .tx-md-thin {
      font-weight: 200;
    }
  
    .tx-md-xthin {
      font-weight: 100;
    }
  
    .lh-md-0 {
      line-height: 0;
    }
  
    .lh-md-1 {
      line-height: 1.1;
    }
  
    .lh-md-2 {
      line-height: 1.2;
    }
  
    .lh-md-3 {
      line-height: 1.3;
    }
  
    .lh-md-4 {
      line-height: 1.4;
    }
  
    .lh-md-5 {
      line-height: 1.5;
    }
  
    .lh-md-6 {
      line-height: 1.6;
    }
  
    .lh-md-7 {
      line-height: 1.7;
    }
  
    .lh-md-8 {
      line-height: 1.8;
    }
  
    .lh-md-9 {
      line-height: 1.9;
    }
  
    .lh-md-10 {
      line-height: 2.0;
    }
  
    .lh-md-11 {
      line-height: 2.1;
    }
  
    .lh-md-12 {
      line-height: 2.2;
    }
  
    .lh-md-13 {
      line-height: 2.3;
    }
  
    .lh-md-14 {
      line-height: 2.4;
    }
  
    .lh-md-15 {
      line-height: 2.5;
    }
  
    .tx-md-center {
      text-align: center;
    }
  
    .tx-md-end {
      text-align: end;
    }
  
    .tx-md-start {
      text-align: start;
    }
  }
  
  @media (min-width: 992px) {
    .tx-lg-8 {
      font-size: 8px;
    }
  
    .tx-lg-9 {
      font-size: 9px;
    }
  
    .tx-lg-10 {
      font-size: 10px;
    }
  
    .tx-lg-11 {
      font-size: 11px;
    }
  
    .tx-lg-12 {
      font-size: 12px;
    }
  
    .tx-lg-13 {
      font-size: 13px;
    }
  
    .tx-lg-14 {
      font-size: 14px;
    }
  
    .tx-lg-15 {
      font-size: 15px;
    }
  
    .tx-lg-16 {
      font-size: 16px;
    }
  
    .tx-lg-18 {
      font-size: 18px;
    }
  
    .tx-lg-20 {
      font-size: 20px;
    }
  
    .tx-lg-22 {
      font-size: 22px;
    }
  
    .tx-lg-24 {
      font-size: 24px;
    }
  
    .tx-lg-26 {
      font-size: 26px;
    }
  
    .tx-lg-28 {
      font-size: 28px;
    }
  
    .tx-lg-30 {
      font-size: 30px;
    }
  
    .tx-lg-32 {
      font-size: 32px;
    }
  
    .tx-lg-34 {
      font-size: 34px;
    }
  
    .tx-lg-36 {
      font-size: 36px;
    }
  
    .tx-lg-38 {
      font-size: 38px;
    }
  
    .tx-lg-40 {
      font-size: 40px;
    }
  
    .tx-lg-42 {
      font-size: 42px;
    }
  
    .tx-lg-44 {
      font-size: 44px;
    }
  
    .tx-lg-46 {
      font-size: 46px;
    }
  
    .tx-lg-48 {
      font-size: 48px;
    }
  
    .tx-lg-50 {
      font-size: 50px;
    }
  
    .tx-lg-52 {
      font-size: 52px;
    }
  
    .tx-lg-54 {
      font-size: 54px;
    }
  
    .tx-lg-56 {
      font-size: 56px;
    }
  
    .tx-lg-58 {
      font-size: 58px;
    }
  
    .tx-lg-60 {
      font-size: 60px;
    }
  
    .tx-lg-62 {
      font-size: 62px;
    }
  
    .tx-lg-64 {
      font-size: 64px;
    }
  
    .tx-lg-66 {
      font-size: 66px;
    }
  
    .tx-lg-68 {
      font-size: 68px;
    }
  
    .tx-lg-70 {
      font-size: 70px;
    }
  
    .tx-lg-72 {
      font-size: 72px;
    }
  
    .tx-lg-74 {
      font-size: 74px;
    }
  
    .tx-lg-76 {
      font-size: 76px;
    }
  
    .tx-lg-78 {
      font-size: 78px;
    }
  
    .tx-lg-80 {
      font-size: 80px;
    }
  
    .tx-lg-82 {
      font-size: 82px;
    }
  
    .tx-lg-84 {
      font-size: 84px;
    }
  
    .tx-lg-86 {
      font-size: 86px;
    }
  
    .tx-lg-88 {
      font-size: 88px;
    }
  
    .tx-lg-90 {
      font-size: 90px;
    }
  
    .tx-lg-92 {
      font-size: 92px;
    }
  
    .tx-lg-94 {
      font-size: 94px;
    }
  
    .tx-lg-96 {
      font-size: 96px;
    }
  
    .tx-lg-98 {
      font-size: 98px;
    }
  
    .tx-lg-100 {
      font-size: 100px;
    }
  
    .tx-lg-102 {
      font-size: 102px;
    }
  
    .tx-lg-104 {
      font-size: 104px;
    }
  
    .tx-lg-106 {
      font-size: 106px;
    }
  
    .tx-lg-108 {
      font-size: 108px;
    }
  
    .tx-lg-110 {
      font-size: 110px;
    }
  
    .tx-lg-112 {
      font-size: 112px;
    }
  
    .tx-lg-114 {
      font-size: 114px;
    }
  
    .tx-lg-116 {
      font-size: 116px;
    }
  
    .tx-lg-118 {
      font-size: 118px;
    }
  
    .tx-lg-120 {
      font-size: 120px;
    }
  
    .tx-lg-122 {
      font-size: 122px;
    }
  
    .tx-lg-124 {
      font-size: 124px;
    }
  
    .tx-lg-126 {
      font-size: 126px;
    }
  
    .tx-lg-128 {
      font-size: 128px;
    }
  
    .tx-lg-130 {
      font-size: 130px;
    }
  
    .tx-lg-132 {
      font-size: 132px;
    }
  
    .tx-lg-134 {
      font-size: 134px;
    }
  
    .tx-lg-136 {
      font-size: 136px;
    }
  
    .tx-lg-138 {
      font-size: 138px;
    }
  
    .tx-lg-140 {
      font-size: 140px;
    }
  
    .tx-lg-base {
      font-size: 0.875rem;
    }
  
    .tx-lg-bold {
      font-weight: 700;
    }
  
    .tx-lg-semibold {
      font-weight: 600;
    }
  
    .tx-lg-medium {
      font-weight: 500;
    }
  
    .tx-lg-normal {
      font-weight: 400;
    }
  
    .tx-lg-light {
      font-weight: 300;
    }
  
    .tx-lg-thin {
      font-weight: 200;
    }
  
    .tx-lg-xthin {
      font-weight: 100;
    }
  
    .lh-lg-0 {
      line-height: 0;
    }
  
    .lh-lg-1 {
      line-height: 1.1;
    }
  
    .lh-lg-2 {
      line-height: 1.2;
    }
  
    .lh-lg-3 {
      line-height: 1.3;
    }
  
    .lh-lg-4 {
      line-height: 1.4;
    }
  
    .lh-lg-5 {
      line-height: 1.5;
    }
  
    .lh-lg-6 {
      line-height: 1.6;
    }
  
    .lh-lg-7 {
      line-height: 1.7;
    }
  
    .lh-lg-8 {
      line-height: 1.8;
    }
  
    .lh-lg-9 {
      line-height: 1.9;
    }
  
    .lh-lg-10 {
      line-height: 2.0;
    }
  
    .lh-lg-11 {
      line-height: 2.1;
    }
  
    .lh-lg-12 {
      line-height: 2.2;
    }
  
    .lh-lg-13 {
      line-height: 2.3;
    }
  
    .lh-lg-14 {
      line-height: 2.4;
    }
  
    .lh-lg-15 {
      line-height: 2.5;
    }
  
    .tx-lg-center {
      text-align: center;
    }
  
    .tx-lg-end {
      text-align: end;
    }
  
    .tx-lg-start {
      text-align: start;
    }
  }
  
  @media (min-width: 1200px) {
    .tx-xl-8 {
      font-size: 8px;
    }
  
    .tx-xl-9 {
      font-size: 9px;
    }
  
    .tx-xl-10 {
      font-size: 10px;
    }
  
    .tx-xl-11 {
      font-size: 11px;
    }
  
    .tx-xl-12 {
      font-size: 12px;
    }
  
    .tx-xl-13 {
      font-size: 13px;
    }
  
    .tx-xl-14 {
      font-size: 14px;
    }

    .tx-xl-15 {
      font-size: 15px;
    }
  
    .tx-xl-16 {
      font-size: 16px;
    }
  
    .tx-xl-18 {
      font-size: 18px;
    }
  
    .tx-xl-20 {
      font-size: 20px;
    }
  
    .tx-xl-22 {
      font-size: 22px;
    }
  
    .tx-xl-24 {
      font-size: 24px;
    }
  
    .tx-xl-26 {
      font-size: 26px;
    }
  
    .tx-xl-28 {
      font-size: 28px;
    }
  
    .tx-xl-30 {
      font-size: 30px;
    }
  
    .tx-xl-32 {
      font-size: 32px;
    }
  
    .tx-xl-34 {
      font-size: 34px;
    }
  
    .tx-xl-36 {
      font-size: 36px;
    }
  
    .tx-xl-38 {
      font-size: 38px;
    }
  
    .tx-xl-40 {
      font-size: 40px;
    }
  
    .tx-xl-42 {
      font-size: 42px;
    }
  
    .tx-xl-44 {
      font-size: 44px;
    }
  
    .tx-xl-46 {
      font-size: 46px;
    }
  
    .tx-xl-48 {
      font-size: 48px;
    }
  
    .tx-xl-50 {
      font-size: 50px;
    }
  
    .tx-xl-52 {
      font-size: 52px;
    }
  
    .tx-xl-54 {
      font-size: 54px;
    }
  
    .tx-xl-56 {
      font-size: 56px;
    }
  
    .tx-xl-58 {
      font-size: 58px;
    }
  
    .tx-xl-60 {
      font-size: 60px;
    }
  
    .tx-xl-62 {
      font-size: 62px;
    }
  
    .tx-xl-64 {
      font-size: 64px;
    }
  
    .tx-xl-66 {
      font-size: 66px;
    }
  
    .tx-xl-68 {
      font-size: 68px;
    }
  
    .tx-xl-70 {
      font-size: 70px;
    }
  
    .tx-xl-72 {
      font-size: 72px;
    }
  
    .tx-xl-74 {
      font-size: 74px;
    }
  
    .tx-xl-76 {
      font-size: 76px;
    }
  
    .tx-xl-78 {
      font-size: 78px;
    }
  
    .tx-xl-80 {
      font-size: 80px;
    }
  
    .tx-xl-82 {
      font-size: 82px;
    }
  
    .tx-xl-84 {
      font-size: 84px;
    }
  
    .tx-xl-86 {
      font-size: 86px;
    }
  
    .tx-xl-88 {
      font-size: 88px;
    }
  
    .tx-xl-90 {
      font-size: 90px;
    }
  
    .tx-xl-92 {
      font-size: 92px;
    }
  
    .tx-xl-94 {
      font-size: 94px;
    }
  
    .tx-xl-96 {
      font-size: 96px;
    }
  
    .tx-xl-98 {
      font-size: 98px;
    }
  
    .tx-xl-100 {
      font-size: 100px;
    }
  
    .tx-xl-102 {
      font-size: 102px;
    }
  
    .tx-xl-104 {
      font-size: 104px;
    }
  
    .tx-xl-106 {
      font-size: 106px;
    }
  
    .tx-xl-108 {
      font-size: 108px;
    }
  
    .tx-xl-110 {
      font-size: 110px;
    }
  
    .tx-xl-112 {
      font-size: 112px;
    }
  
    .tx-xl-114 {
      font-size: 114px;
    }
  
    .tx-xl-116 {
      font-size: 116px;
    }
  
    .tx-xl-118 {
      font-size: 118px;
    }
  
    .tx-xl-120 {
      font-size: 120px;
    }
  
    .tx-xl-122 {
      font-size: 122px;
    }
  
    .tx-xl-124 {
      font-size: 124px;
    }
  
    .tx-xl-126 {
      font-size: 126px;
    }
  
    .tx-xl-128 {
      font-size: 128px;
    }
  
    .tx-xl-130 {
      font-size: 130px;
    }
  
    .tx-xl-132 {
      font-size: 132px;
    }
  
    .tx-xl-134 {
      font-size: 134px;
    }
  
    .tx-xl-136 {
      font-size: 136px;
    }
  
    .tx-xl-138 {
      font-size: 138px;
    }
  
    .tx-xl-140 {
      font-size: 140px;
    }
  
    .tx-xl-base {
      font-size: 0.875rem;
    }
  
    .tx-xl-bold {
      font-weight: 700;
    }
  
    .tx-xl-semibold {
      font-weight: 600;
    }
  
    .tx-xl-medium {
      font-weight: 500;
    }
  
    .tx-xl-normal {
      font-weight: 400;
    }
  
    .tx-xl-light {
      font-weight: 300;
    }
  
    .tx-xl-thin {
      font-weight: 200;
    }
  
    .tx-xl-xthin {
      font-weight: 100;
    }
  
    .lh-xl-0 {
      line-height: 0;
    }
  
    .lh-xl-1 {
      line-height: 1.1;
    }
  
    .lh-xl-2 {
      line-height: 1.2;
    }
  
    .lh-xl-3 {
      line-height: 1.3;
    }
  
    .lh-xl-4 {
      line-height: 1.4;
    }
  
    .lh-xl-5 {
      line-height: 1.5;
    }
  
    .lh-xl-6 {
      line-height: 1.6;
    }
  
    .lh-xl-7 {
      line-height: 1.7;
    }
  
    .lh-xl-8 {
      line-height: 1.8;
    }
  
    .lh-xl-9 {
      line-height: 1.9;
    }
  
    .lh-xl-10 {
      line-height: 2.0;
    }
  
    .lh-xl-11 {
      line-height: 2.1;
    }
  
    .lh-xl-12 {
      line-height: 2.2;
    }
  
    .lh-xl-13 {
      line-height: 2.3;
    }
  
    .lh-xl-14 {
      line-height: 2.4;
    }
  
    .lh-xl-15 {
      line-height: 2.5;
    }
  
    .tx-xl-center {
      text-align: center;
    }
  
    .tx-xl-end {
      text-align: end;
    }
  
    .tx-xl-start {
      text-align: start;
    }
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  @media (max-width: 576px) {
    .text-sm-nowrap {
      white-space: nowrap !important;
    }
  }
  
  @media (max-width: 768px) {
    .text-md-nowrap {
      white-space: nowrap !important;
    }
  }
  
  @media (max-width: 992px) {
    .text-lg-nowrap {
      white-space: nowrap !important;
    }
  }
  
  @media (max-width: 1000px) {
    .text-xl-nowrap {
      white-space: nowrap !important;
    }
  }
  
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
  
  .text-justify {
    text-align: justify !important;
  }
  
  .text-wrap {
    white-space: normal !important;
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .text-start {
    text-align: start !important;
  }
  
  .text-end {
    text-align: end !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  @media (min-width: 576px) {
    .text-sm-start {
      text-align: start !important;
    }
  
    .text-sm-end {
      text-align: end !important;
    }
  
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-md-start {
      text-align: start !important;
    }
  
    .text-md-end {
      text-align: end !important;
    }
  
    .text-md-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 992px) {
    .text-lg-start {
      text-align: start !important;
    }
  
    .text-lg-end {
      text-align: end !important;
    }
  
    .text-lg-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1200px) {
    .text-xl-start {
      text-align: start !important;
    }
  
    .text-xl-end {
      text-align: end !important;
    }
  
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  .text-lowercase {
    text-transform: lowercase !important;
  }
  
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .text-capitalize {
    text-transform: capitalize !important;
  }
  
  .font-weight-light {
    font-weight: 300 !important;
  }
  
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  
  .font-weight-normal {
    font-weight: 400 !important;
  }
  
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  .font-weight-bolder {
    font-weight: bolder !important;
  }
  
  .font-italic {
    font-style: italic !important;
  }
  
  .text-white {
    color: $white !important;
  }
  
  .text-primary {
    color: $primary !important;
  }
  
  a.text-primary {
    &:hover, &:focus {
      color: #8485fb !important;
    }
  }
  
  .text-secondary {
    color: $secondary !important;
  }
  
  a.text-secondary {
    &:hover, &:focus {
      color: #556179 !important;
    }
  }
  
  .text-success {
    color: $success !important;
  }
  
  a.text-success {
    &:hover, &:focus {
      color: #226401 !important;
    }
  }
  
  .text-info {
    color: $info !important;
  }
  
  a.text-info {
    &:hover, &:focus {
      color: #0f6674 !important;
    }
  }
  
  .text-warning {
    color: #e8b210  !important;
  }
  
  a.text-warning {
    &:hover, &:focus {
      color: #ba8b00 !important;
    }
  }
  
  .text-purple {
    color: $purple!important;
  }
  
  a.text-purple {
    &:hover, &:focus {
      color: $purple!important;
    }
  }
  
  .text-danger {
    color: $danger  !important;
  }
  
  a.text-danger {
    &:hover, &:focus {
      color: #a71d2a !important;
    }
  }
  
  .text-pink {
    color: $pink !important;
  }
  
  a.text-pink {
    &:hover, &:focus {
      color: #e61078 !important;
    }
  }
  
  .text-orange {
    color: $orange !important;
  }
  
  a.text-orange {
    &:hover, &:focus {
      color: #e46f0e !important;
    }
  }
  
  .text-light {
    color: $gray-100 !important;
  }
  
  a.text-light {
    &:hover, &:focus {
      color: #c5cbda !important;
    }
  }
  
  .text-teal {
    color: $teal !important;
  }
  
  a.text-teal {
    &:hover, &:focus {
      color: #06c1c1 !important;
    }
  }
  
  .text-dark {
    color: $gray-800 !important;
  }
  
  a.text-dark {
    &:hover, &:focus {
      color: #1e2533 !important;
    }
  }
  
  .text-body {
    color: $default-color !important;
  }
  
  .text-muted {
    color: $muted  !important;
  }
  
  .text-black-50 {
    color: $black-5 !important;
  }
  
  .text-white-50 {
    color: $white-5 !important;
  }
  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .text-decoration-none {
    text-decoration: none !important;
  }
  
  .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }
  
  .text-reset {
    color: inherit !important;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }

  /* ###### Typography End ###### */